import React,{ useEffect, useState } from 'react'
import { Button, Row, Col, Typography, Table, Space } from 'antd'
import { ActionButton, ModuleTopHeading } from '../../PageComponents';
import { AlertModal } from '../../AlertModal';
import { AddEditExpenseDrawer } from '../../ExpenseComponents/AddEditExpenseDrawer';
import { expenseColumns } from '../../../data/columns';
import { actionsApi, TableLoader } from '../../../shared';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { apiCalls } from '../../../shared/Apis';
import { useTranslation } from 'react-i18next';


const { Text } = Typography

const ExpenseReportTable = ({getPayload}) => {

    const {t}= useTranslation()
    const dispatch= useDispatch()
    const {expense, loadingExpense, expensePagination}= useSelector(state => state?.report)
    const {language}= useSelector(state => state?.app)
    const [ visible, setVisible ] = useState(false)
    const [ editExpense, setEditExpense ] = useState(null)
    const [ deleteItem, setDeleteItem ] = useState(false)
    const [ reportonly, setReportOnly ] = useState(true)
    const [deletionLoading, setDeletionLoading]= useState(false)
    const user= localStorage.getItem("user")
    const {role}= user ? JSON.parse(user) : {role: null}

    useEffect(()=>{
        dispatch(actionsApi?.getPropertyLookup())
    }, [])
    const getAllExpense= ()=>{
        dispatch(actionsApi?.getAllExpenseForReport({...expensePagination, pageNo:1, payload: getPayload()}))
    }
    const columns = [
        ...expenseColumns,
        role!=='host' ?
        {
            title: <Text strong>{t("Action")}</Text>,
            key: 'action',
            fixed: 'right',
            align: language === 'ar' ? 'right' : 'left',
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title={t('Edit expense')}
                        shape="circle"
                        icon={<EditOutlined/>}
                        onClick={() => { setVisible(true); setEditExpense(row)}}
                    />
                    <ActionButton
                        title={t('Delete expense')}
                        icon={<DeleteOutlined />}
                        danger
                        onClick={() => { setDeleteItem({id: row?.id, name: row?.name}) }}
                    />
                </Space>
            ),
        }:{}
    ]
    const deleteExpense= async ()=>{
        setDeletionLoading(true)
        let result= await apiCalls?.expense?.deleteExpense(deleteItem?.id)
        if(result){
            setDeleteItem(null)
            getAllExpense()
        }
        setDeletionLoading(false)
    }
  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <ModuleTopHeading 
                    name={t('Expense Details')}
                    onClick={()=>{setVisible(true);setReportOnly(true)}}
                 />
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={expense} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    pagination={{
                        hideOnSinglePage: true,
                        total: 12,
                        pageSize: expensePagination?.pageSize,
                        defaultPageSize: expensePagination?.pageSize,
                        current: expensePagination?.pageNo,
                        size: "default",
                        pageSizeOptions: ['10', '20', '50', '100'],
                        // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        showTotal: (total) => <Button className='bg-secondary-color border0'>Total: {total}</Button>,
                    }}
                    loading={
                        {
                            ...TableLoader,
                            spinning: loadingExpense
                        }
                    }
                />
            </Col>
        </Row>
        <AddEditExpenseDrawer
            visible={visible}
            editExpense={editExpense}
            reportonly={reportonly}
            callBack={getAllExpense}
            onClose={()=> {setVisible(false);setEditExpense(null)}}
        />
        <AlertModal 
            visible={deleteItem}
            callBack={deleteExpense}
            deletionLoading= {deletionLoading}
            onClose={()=>setDeleteItem(false)}
        />
    </div>
  )
}

export {ExpenseReportTable}