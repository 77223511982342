import { Radio } from 'antd'
const MyRadioGroup = ({options, defaultValue, ...props}) => (
    <Radio.Group
      block
      options={options}
      defaultValue={defaultValue}
      optionType="button"
      buttonStyle="solid"
      {...props}
    />
);
export {MyRadioGroup}