import { useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import { Button, Card, Col, Dropdown, Flex, Form, Image, message, Row, Space, Table, Typography } from 'antd'
import { ModuleTopHeading, MySelect, MyRentDate, ActionButton} from '../../components'
import { json, useNavigate , useParams} from 'react-router-dom'
import { BookingReportTable } from '../../components/Report/BookingReportTable'
import { ExpenseReportTable } from '../../components/Report/ExpenseReportTable'
import { RentReportTable } from '../../components/Report/RentReportTable'
import { actionsApi } from '../../shared/redux/actionsApi'
import { onlyMonth, onlyYear, TableLoader } from '../../shared'
import { BankTransfer, GeneratePdfReport, ReportNotesTable } from '../../components/Report'
import { useTranslation } from 'react-i18next'
import { BankOutlined, PrinterOutlined } from '@ant-design/icons'
import { render } from '@testing-library/react'
import { integerFLoat } from '../../shared/helpers/HelperFuntions'

const { Text } = Typography

const Report = () => {
    
    const {t}= useTranslation()
    const dispatch= useDispatch()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const {propertyID, clientID}= useParams()
    const {clientLookup}= useSelector(state => state?.client)
    const {propertyByClientLookup}= useSelector(state => state?.property)
    const {data, loading, bookingPagination, expensePagination, rentPagination}= useSelector(state => state?.report)
    const {language}= useSelector(state => state?.app)
    const [visiblePdfModal, setVisiblePdfModal]= useState(false)
    const [bankTransfer, setBankTransfer]= useState(null)
    const user= localStorage.getItem("user")
    const {role}= user ? JSON.parse(user) : {role: null}

    useEffect(()=>{
        if(!clientLookup?.length){
            dispatch(actionsApi?.getClientLookup())
        }
        else if(clientID && clientLookup?.length){
            form.setFieldValue("clientID", clientLookup?.find(fin => fin?.id === clientID)?.name)
            getPropertyByClient(clientID)
        }
        else if(!clientID)
            getPropertyByClient(clientID)
    }, [propertyID, clientID, clientLookup])

    useEffect(()=>{
        if(propertyByClientLookup?.length && propertyID)
            form.setFieldValue("propertyID", propertyByClientLookup?.find(fin => fin?.id === propertyID)?.name)
    }, [propertyByClientLookup, propertyID])
   
    
    const searchReport= ()=>{
        
        dispatch(actionsApi?.getReport({payload: getPayload()}))
        dispatch(actionsApi?.getAllBookingsForReport({...bookingPagination, pageNo:1, payload: getPayload()}))
        dispatch(actionsApi?.getAllExpenseForReport({...expensePagination, pageNo:1, payload: getPayload()}))
        dispatch(actionsApi?.getAllRentForReport({...rentPagination, pageNo:1, payload: getPayload()}))
        dispatch(actionsApi?.getAllReportNotes(getPayload()))
    }
    const getPayload= ()=>{
        let data= form.getFieldsValue()
        return {
            propertyID: propertyByClientLookup?.find(fin => fin?.name === data?.propertyID).id,
            year: onlyYear(data?.year),
            month: onlyMonth(data?.month),
        }
    }
    const getPropertyByClient= (clientID)=>{
        dispatch(actionsApi?.getPropertyByClientLookup({clientID, propertyAddedFor: clientID ? 2 : 1}))
    }
    const columns = [
        {
            title: <Text strong>{t("Income")}</Text>,
            dataIndex: 'income',
            key: 'income',
            align: language === 'ar' ? 'right' : 'left'
        },
        {
            title: <Text strong>{t("Rent")}</Text>,
            dataIndex: 'rent',
            key:'rent',
            align: language === 'ar' ? 'right' : 'left'
        },
        {
            title: <Text strong>{t("Expense")}</Text>,
            dataIndex: 'expenses',
            key:'expenses',
            align: language === 'ar' ? 'right' : 'left'
        },
        {
            title: <Text strong>{t("Our Comission")}</Text>,
            dataIndex: 'ourCommission',
            key:'ourCommission',
            align: language === 'ar' ? 'right' : 'left'
        },
        {
            title: <Text strong>{t("Airbnb Comission")}</Text>,
            dataIndex: 'airbnbComission',
            key:'airbnbComission',
            align: language === 'ar' ? 'right' : 'left',
            render: col => integerFLoat(col)
        },
        {
            title: <Text strong>{t("Total Booking")}</Text>,
            dataIndex: 'totalBooking',
            key:'totalBooking',
            align: language === 'ar' ? 'right' : 'left'
        },
        {
            title: <Text strong>{t("Bank Transfer")}</Text>,
            dataIndex: 'bankTransfer',
            key:'bankTransfer',
            align: language === 'ar' ? 'right' : 'left'
        },
        {
            title: <Text strong>{t("Remaining")}</Text>,
            dataIndex: 'remaining',
            key:'remaining',
            align: language === 'ar' ? 'right' : 'left',
            render: (_, row)=> integerFLoat(row?.income-(parseFloat(row?.rent) + parseFloat(row?.expenses) + parseInt(row?.ourCommission) + parseFloat(row?.airbnbComission) + parseInt(row?.bankTransfer)))
        },
        {
            title: <Text strong>{t("Action")}</Text>,
            key: 'action',
            fixed: 'right',
            width: 180,
            align: language === 'ar' ? 'right' : 'left',
            render: (_, row) => (
                <>
                    {
                        role!=='host' && 
                        <Dropdown 
                            menu={{
                                items: [
                                    {key: '1', label: 'Bank transfer'},
                                    {key: '2', label: 'Edit bank transfer'}
                                ],
                                onClick: (e)=>{
                                    const {year, month}= form.getFieldsValue()
                                    if(!year || !month){
                                        message.warning("please select year and month from filters")
                                        return
                                    }
                                    let isEdit= false
                                    if(parseInt(e.key) === 2)
                                        isEdit=true
                                    setBankTransfer({
                                        propertyID,
                                        year,
                                        month,
                                        income: row?.income,
                                        ourCommission: row?.ourCommission,
                                        airbnbComission: row?.airbnbComission,
                                        expenses: row?.expenses,
                                        rent: row?.rent,
                                        bankTransfer: row?.bankTransfer, 
                                        remaining: row?.remaining,
                                        isEdit
                                    })
                                }
                            }} 
                            placement="bottom" 
                            arrow
                        >
                            <ActionButton
                                title={t('bank transfer')}
                                icon={<BankOutlined/>}
                                type='primary'
                                style={{background: "#22577A"}}
                            />
                        </Dropdown>
                    }
                </>
            )
        }
    ]
    return (
        <div>
            <Card className='shadow-c radius-12 border0'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Space>
                            <Button onClick={() => navigate(-1)} className='bg-transparent border0 p-0'>
                                <Image src='/assets/icons/backarr.png' width={30} preview={false} />
                            </Button>
                            <ModuleTopHeading name={t('Report')} />
                        </Space>                     
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Flex justify='space-between' align='center' className='mbl-vertical' gap={10}>
                                <Space size={12} wrap className='w-100 mbl-space'>
                                    <MySelect
                                        name='clientID'
                                        label={t('Client')}
                                        size='large' 
                                        placeholder='select...'
                                        options={clientLookup}
                                        className='width-200'
                                        showSearch
                                        onChange= {(value)=>{
                                            value= clientLookup?.find(fin => fin?.name === value).id
                                            getPropertyByClient(value)
                                            form.setFieldValue("propertyID", null)
                                        }}
                                        disabled={role==='host'}
                                    />
                                    <MySelect
                                        name='propertyID'
                                        label={t('Property')}
                                        size='large' 
                                        placeholder='select...'
                                        options={propertyByClientLookup}
                                        className='width-200'
                                        showSearch
                                        disabled={role==='host'}
                                    />
                                    <MyRentDate
                                        datePicker
                                        label={t("Year")}
                                        name='year'
                                        size='large'
                                        picker="year"
                                        placeholder= {t("select year")}
                                        className='width-200'
                                    />
                                    <MyRentDate
                                        datePicker
                                        label={t("Month")}
                                        name='month'
                                        size='large'
                                        picker="month"
                                        placeholder= {t("select month")}
                                        className='width-200'
                                    />
                                    <Button
                                        type='primary'
                                        size='large'
                                        onClick={searchReport}
                                        className='trans-down'
                                    >
                                        {t("Search")}
                                    </Button>
                                </Space>
                                <div>
                                    <Button 
                                        type='primary'
                                        size='large'
                                        icon={<PrinterOutlined/>}
                                        onClick={()=> setVisiblePdfModal(true)}
                                    >
                                        {t("Print")}
                                    </Button>
                                </div>
                            </Flex>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Space direction='vertical' size={20} className='w-100'>
                            <ModuleTopHeading name={t('Overview')} />
                            <Table 
                                size='large'
                                columns={columns} 
                                dataSource={data} 
                                className='table-dark pagination'
                                rowHoverable={false}
                                pagination={false}
                                scroll={{x: "max-content"}}
                                loading={
                                    {
                                        ...TableLoader,
                                        spinning: loading
                                    }
                                }
                            /> 
                        </Space>             
                    </Col>
                    <Col span={24}>
                        <BookingReportTable {...{getPayload}}/>
                    </Col>
                    <Col span={24}>
                        <ExpenseReportTable {...{getPayload}}/>
                    </Col>
                    <Col span={24}>
                        <RentReportTable {...{getPayload}}/>
                    </Col>
                    <Col span={24}>
                        <ReportNotesTable {...{getPayload}}/>
                    </Col>
                </Row>
            </Card>
            {
                visiblePdfModal &&
                <GeneratePdfReport
                    visible={visiblePdfModal}
                    onClose={()=>{setVisiblePdfModal(false)}}
                />
            }
            <BankTransfer
                bankTransfer={bankTransfer}
                onClose={()=> setBankTransfer(null)}
                callBack={()=>  dispatch(actionsApi?.getReport({payload: getPayload()}))}
            />
        </div>
    )
}

export { Report }
