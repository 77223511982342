import { useState } from "react"
import { Form, Image, Button, Space, Row, Col, Typography, Flex } from "antd"
import {  MyInput } from '../../components'
import { useDispatch, useSelector } from "react-redux"
import { actionsApi } from "../../shared"
import "./index.css"

const {Title, Text}= Typography

const Login = () => {

    const appDispatcher = useDispatch()
    const [form] = Form.useForm()
    const { loading } = useSelector(state => state?.login)
    const [forgotPassword, setForgotPassword]= useState(false)

    const login = () => {
        if(forgotPassword)
            appDispatcher(actionsApi?.forgotPassword(form.getFieldsValue(['email'])))
        else
            appDispatcher(actionsApi?.login(form.getFieldsValue(['email', 'password'])))

    }

    return (
        <Flex className="login-form-cover" justify="center" align="center">
            <div className="login-form">
                <div className="brand-bg center login-form-left-side py-5">
                    <Image 
                        src="/assets/images/logo.png" 
                        preview={false} 
                        alt="rental-logo"
                    />
                    <div>
                        <Text strong className="pt-3 danger-color">
                            Version 0.0 Beta Release
                        </Text>
                    </div>
                </div>
                <div 
                    style={{alignItems: 'center', display:'flex'}}
                    className="login-form-right-side px-3 py-5"
                >
                    <Space 
                        direction='vertical' 
                        className='justify-center w-100'
                        size={15}
                    >
                        <Image 
                            src="/assets/images/logo-sm.png" 
                            width={'90px'} 
                            preview={false} 
                            alt="najla-rental-logo"
                        />
                        <Space direction="vertical">
                            <Title level={3} className="my-0">
                                Welcome to Najla Relator System
                            </Title>
                            <Text className="text-gray">
                                Enter the credentials to login to the system
                            </Text>
                        </Space>
                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={{
                                remember: true,
                            }}
                            style={{width: '100%'}}
                            onFinish={login}
                            requiredMark={false}
                        >
                            <Row>
                                <Col span={24}>
                                    <MyInput
                                        autoFocus
                                        name="email"
                                        label="Email Address"
                                        required
                                        size={'large'}
                                        message='Enter email address'
                                        value={form.getFieldValue("email") || ''}
                                    />
                                </Col>
                                <Col span={24}>
                                    <MyInput
                                        name="password"
                                        type='password'
                                        label="Password"
                                        required
                                        size={'large'}
                                        message='Enter password'
                                        value={form.getFieldValue("password") || ''}
                                    />
                                </Col>
                                <Col span={24} className="center">
                                    <Button 
                                        htmlType="submit" 
                                        size="large"
                                        block
                                        loading={loading}
                                        className="fs-14 bg-black text-white"
                                        >
                                        Signin
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Space>
                </div>
            </div>
        </Flex>
    )
}
export {Login}