import React,{ useState } from 'react'
import { Row, Col, Typography, Table, Space } from 'antd'
import { ActionButton, ModuleTopHeading } from '../../PageComponents'
import { AlertModal } from '../../AlertModal'
import { useDispatch, useSelector } from 'react-redux'
import { actionsApi, TableLoader } from '../../../shared';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { AddEditNote } from '../Drawer'
import { ColumnName } from '../../../data/ColumnName'
import { apiCalls } from '../../../shared/Apis'

const { Text } = Typography

const ReportNotesTable = ({getPayload}) => {

    const {t}= useTranslation()
    const dispatch= useDispatch()
    const {notes, loadingNotes}= useSelector(state => state?.report)
    const {language}= useSelector(state => state?.app)
    const [ visible, setVisible ] = useState(false)
    const [ editNote, setEditNote ] = useState(null)
    const [ deleteItem, setDeleteItem ] = useState(false)
    const [deletionLoading, setDeletionLoading]= useState(false)

    const user= localStorage.getItem("user")
    const {role}= user ? JSON.parse(user) : {role: null}

    const columns = [
        {
            title: <ColumnName name="Note"/>,
            dataIndex: 'note',
            fixed: 'left',
            align: language === 'ar' ? 'right' : 'left'
        },
        role !=='host' ?
            {
                title: <Text strong>{t("Action")}</Text>,
                key: 'action',
                fixed: 'right',
                align: language === 'ar' ? 'right' : 'left',
                render: (_, row) => (
                    <Space> 
                        <ActionButton
                            title={t('edit note')}
                            shape="circle"
                            icon={<EditOutlined/>}
                            onClick={() => { setVisible(true); setEditNote(row)}}
                        />
                        <ActionButton
                            title={t('delete note')}
                            icon={<DeleteOutlined/>}
                            danger
                            onClick={() => { setDeleteItem({id: row?.id, name: 'Note'}) }}
                        />
                    </Space>
                ),
            }
        :
        {}
    ]
    const deleteNote= async ()=>{
        setDeletionLoading(true)
        let result= await apiCalls?.notes?.deleteNote(deleteItem?.id)
        if(result){
            setDeleteItem(null)
            callBack()
        }
        setDeletionLoading(false)
    }
    const callBack= ()=>{
        dispatch(actionsApi?.getAllReportNotes(getPayload()))
    }
  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <ModuleTopHeading 
                    name={t('Notes')}
                    onClick={()=>{setVisible(true)}}
                />
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={notes} 
                    className='table-dark pagination'
                    rowHoverable={false}
                    pagination={false}
                    loading={
                        {
                            ...TableLoader,
                            spinning: loadingNotes
                        }
                    }
                />
            </Col>
        </Row>
        <AddEditNote
            visible={visible}
            editNote={editNote}
            getPayload={getPayload}
            callBack={callBack}
            onClose={()=> {setVisible(false); setEditNote(null) }}
        />
        <AlertModal 
            visible={deleteItem}
            onClose={()=> setDeleteItem(false)}
            callBack={deleteNote}
            deletionLoading= {deletionLoading}
        />
    </div>
  )
}

export {ReportNotesTable}