import { Flex, Typography, Tag} from "antd";
import { ColumnName } from "../ColumnName"
import { propertyOwnership } from "../../shared/lookups";
const {Text}= Typography

let lang= localStorage.getItem("lang")
lang= lang || 'en'

export const propertyColumn=[
    {
        title: 
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Property name"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'propertyName',
        key: 'propertyName',
        align: lang === 'ar' ? 'right' : 'left'
    },
    {
        title: 
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Client name"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'client',
        key: 'client',
        align: lang === 'ar' ? 'right' : 'left',
        render: col => <Text>{col?.name || 'Najla'}</Text>
    },
    {
        title:
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Property address"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'address',
        key:'address',
        align: lang === 'ar' ? 'right' : 'left'
    },
    {
        title:
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Property type"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'propertyType',
        key:'propertyType',
        align: lang === 'ar' ? 'right' : 'left'
    },
    {
        title: 
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Ownership"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'ownerShip',
        key:'ownerShip',
        align: lang === 'ar' ? 'right' : 'left',
        render: col => <Tag color={parseInt(col) === 1 ? '#7574B4' : "#f50"}>{propertyOwnership.find(fin => fin?.id === parseInt(col))?.name}</Tag>
    }
]