import { message } from "antd";
import { checkAuthorization } from "../../helpers";
import { domainUrl } from "../../constants";

const addUpdateProperty = (formData) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    }
    let url = formData.get("id") ? '/updateProperty' :'/storeProperty'
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error || 'Something went wrong');
            return 0
        })
    )
}
const addUpdatePropertyImages = (formData) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    }
    return (
        fetch(domainUrl + "/addPropertyImages", requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error || 'Something went wrong');
            return 0
        })
    )
}
const deletePropertyImage = (imageID) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
    }
    return (
        fetch(domainUrl + "/deletePropertyImage/"+ imageID, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error || 'Something went wrong');
            return 0
        })
    )
}

const deleteProperty= (Id)=>{
    const {userToken} = checkAuthorization();
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    return(
        fetch(domainUrl + `/deleteProperty/${Id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error || 'Something went wrong');
            return 0
        })
    )
  }

const property = {
    addUpdateProperty,
    addUpdatePropertyImages,
    deletePropertyImage,
    deleteProperty
}

export {property}