import { useState } from "react"
import { Avatar } from "antd"
import {UserOutlined} from '@ant-design/icons'
import UserProfileDrawer from "./UserProfileDrawer"

export const UserProfile = () => {
    const [visible, setVisible]= useState(false)
    return (
        <>
          <Avatar 
            size={32} 
            icon={<UserOutlined style={{fontSize: '19px'}}/>} 
            style={{cursor: 'pointer'}}
            onClick={()=> setVisible(true)}
          />
          <UserProfileDrawer
            visible={visible}
            onClose={()=> setVisible(false)}
          />
        </>
    )
}