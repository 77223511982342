import React, { useEffect, useState } from 'react'
import { Card, Col, Flex, Image, Row, Table, Typography, Space, Button, Dropdown } from 'antd'
import { ActionButton, AlertModal, ModuleTopHeading, MyInput } from '../../components';
import { AddEditClientDrawer, PropertyModal, ViewClient } from '../../components/ClientComponents';
import { DownOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { actionsApi } from '../../shared/redux/actionsApi';
import { apiCalls } from '../../shared/Apis';
import { TableLoader, simpleDateFormatUtcToLocal} from '../../shared'
import { useTranslation } from 'react-i18next';

const suffix = (<Image src='/assets/icons/search.png' width={'18px'} preview={false} />)
const { Text } = Typography
let timer

const Client = () => {

    const {t}= useTranslation()
    const dispatch= useDispatch()
    const {data, loading, filters, pagination}= useSelector(state => state?.client)
    const {language}= useSelector(state => state?.app)
    const [ visible, setVisible ] = useState(false)
    const [visiblemodal, setVisibleModal] = useState(false)
    const [viewData, setViewData]= useState(null)
    const [ editclient, setEditClient ] = useState(null)
    const [ deleteItem, setDeleteItem ] = useState()
    const [name , setName]= useState('')
    const [order, setOrder] = useState(1)
    
    useEffect(()=>{
        dispatch(actionsApi?.getAllClient({...filters, ...pagination, pageNo: 1}))
        setName(filters?.name)
    }, [])
    const call=(pageNo=1, pageSize=8)=>{
        dispatch(actionsApi?.getAllApplications({...filters, pageNo, pageSize}))
    }

    const columns = [
        {
            title:
                <Flex align='center' gap={4} justify={language === "ar" ? "end" : "start"}>
                    <Text strong>
                        {t("Client Name")}
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'name',
            align: language === 'ar' ? 'right' : 'left'
        },
        {
            title:
                <Flex align='center' gap={4} justify={language === "ar" ? "end" : "start"}>
                    <Text strong>
                        {t("Phone no")}
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'phoneNo',
            align: language === 'ar' ? 'right' : 'left'
        },
        {
            title:
                <Flex align='center' gap={4} justify={language === "ar" ? "end" : "start"}>
                    <Text strong>
                        {t("Email")}
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'email',
            align: language === 'ar' ? 'right' : 'left'
        },
        {
            title:
                <Flex align='center' gap={4} justify={language === "ar" ? "end" : "start"}>
                    <Text strong>
                        {t("City")}
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'city',
            align: language === 'ar' ? 'right' : 'left'
        },
        {
            title: 
                <Flex align='center' gap={4} justify={language === "ar" ? "end" : "start"}>
                    <Text strong>
                        {t("Properties")}
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'properties_count',
            align: language === 'ar' ? 'right' : 'left'
        },
        {
            title: 
                <Flex align='center' gap={4} justify={language === "ar" ? "end" : "start"}>
                    <Text strong>
                        {t("Time Stamp")}
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'created_at',
            align: language === 'ar' ? 'right' : 'left',
            render: col => <Text>{simpleDateFormatUtcToLocal(col)}</Text>
        },
        {
            title: <Text strong>{t("Action")}</Text>,
            key: 'action',
            fixed: 'right',
            width: 200,
            align: language === 'ar' ? 'right' : 'left',
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title={t('View client')}
                        icon={<EyeOutlined/>}
                        onClick={() => setViewData(row)}
                    />
                    <ActionButton
                        title={t('Edit client')}
                        icon={<Image src='/assets/icons/edit.png' width={16} preview={false} />}
                        onClick={() => { setVisible(true);setEditClient(_)}}
                    />
                    <ActionButton
                        title={t('Properties')}
                        icon={<Image src='/assets/icons/property.png' width={16} preview={false} />}
                        onClick={() => { setVisibleModal(true)}}
                    />
                    <ActionButton
                        title={t('Delete client')}
                        icon={<Image src='/assets/icons/delete.png' width={16} preview={false} />}
                        onClick={() => { setDeleteItem({id: row?.id, name: row?.name}) }}
                        danger
                    />
                </Space>
            ),
        },
    ]
    function searchHandler(searchKeyword) {
        dispatch(actionsApi?.getAllClient({name: searchKeyword, order, pageNo:1, pageSize: 10}))
        dispatch(actionsApi?.changeClientFilters({name: searchKeyword}))
    }
    function debounce(func, delay) {
        return function(...args) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(this, args)
            }, delay)
        };
    }
    const debouncedSearchHandler = debounce(searchHandler, 400)
    const [deletionLoading, setDeletionLoading]= useState(false)
    const deleteClient= async ()=>{
        setDeletionLoading(true)
        let result= await apiCalls?.client?.deleteClient(deleteItem?.id)
        if(result){
            setDeleteItem(null)
            searchHandler('')
        }
        setDeletionLoading(false)
    }
    return (
        <div>
            
            <Card className='radius-12 border0 shadow-c'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' wrap gap={10}>
                            <ModuleTopHeading name={t('Client')} onClick={()=>setVisible(true)} />
                            <Flex justify='end' gap={10}>
                                <MyInput
                                    withoutForm
                                    prefix={suffix}
                                    placeholder={t('Search client ...')}
                                    value={name || ''}
                                    onChange={e => {debouncedSearchHandler(e.target.value); setName(e.target.value)}}
                                />
                                <Dropdown
                                    menu={{ 
                                        items: [
                                            { label: <a href="#">A-Z</a>, key: '0' },
                                            { label: <a href="#">Z-A</a>, key: '1' }
                                        ], 
                                        onClick:  ({ key }) => {
                                            setOrder(key)

                                        }
                                    }}
                                    trigger={['click']}
                                    className='margin-top'
                                    >
                                    <Button
                                        type='primary'
                                        size='large'
                                        icon={<DownOutlined/>}
                                    >
                                        {t("Filter")}
                                    </Button>        
                                </Dropdown>   
                            </Flex>
                        </Flex>                        
                    </Col>
                    <Col span={24}>
                        <Table
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: pagination?.totalRecords,
                                pageSize: pagination?.pageSize,
                                defaultPageSize: pagination?.pageSize,
                                current: pagination?.pageNo,
                                size: "default",
                                pageSizeOptions: ['10', '20', '50', '100'],
                                onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='bg-secondary-color border0'>Total: {total}</Button>,
                            }}
                            loading={
                                {
                                    ...TableLoader,
                                    spinning: loading
                                }
                            }
                        />
                    </Col>
                </Row>
            </Card>
            <ViewClient
                viewData={viewData}
                onClose={()=> setViewData(null)}
            />
            <AddEditClientDrawer
                visible={visible}
                editclient={editclient}
                onClose={()=> {setVisible(false);setEditClient(null)}}
                searchHandler={searchHandler}
            />
            <AlertModal 
                visible={deleteItem}
                callBack= {deleteClient}
                deletionLoading={deletionLoading}
                onClose={()=>setDeleteItem(null)}
            />
            <PropertyModal 
                visiblemodal={visiblemodal}
                onClose={()=> setVisibleModal(false)}
            />
        </div>
    )
}

export { Client }
