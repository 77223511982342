import { useEffect, useState } from "react"
import { Modal, Button, Space, Divider, Typography, Form, message } from "antd"
import { useTranslation } from "react-i18next"
import "./index.css"
import { MyInput } from "../../Forms"
import { apiCalls } from "../../../shared/Apis"
import { onlyMonth, onlyYear } from "../../../shared"
import { integerFLoat } from "../../../shared/helpers/HelperFuntions"
const {Title, Text}= Typography

const BankTransfer = ({ bankTransfer, callBack, onClose }) => {

  const {t}= useTranslation()
  const [form] = Form.useForm()
  const [loading, setLoading]= useState(false)

  useEffect(()=>{
    if(!bankTransfer)
        form.resetFields()
  }, [bankTransfer])
  const transferMoney= async ()=>{
    setLoading(true)
    let amount= form.getFieldValue("amount")
    let data={ 
        propertyID: bankTransfer?.propertyID,
        year: onlyYear(bankTransfer?.year),
        month: onlyMonth(bankTransfer?.month),
        amount,
        isEdit: bankTransfer?.isEdit
    }
    let result= await apiCalls?.bankTransfer?.transferMoney(data)
    if(result){
        callBack()
        onClose()
    }
    setLoading(false)
  }
console.log(bankTransfer)
  return (
    <Modal
      title={'Transfer Amount'}
      open={bankTransfer}
      onOk={onClose} 
      onCancel={onClose}
      width={700}
      closeIcon={false}
      centered
      footer={null}
      className="bank-transfer-modal"
    >
        <div className="py-3">
            <Space style={{justifyContent: "space-between", width: "100%"}}>
                <Title level={5} className='m-0 fw-500'>Income:</Title>
                <Text>
                    {
                        bankTransfer?.income
                    }
                </Text>
            </Space>
            <Divider/>
            <Space style={{justifyContent: "space-between", width: "100%"}}>
                <Title level={5} className='m-0 fw-500'>Rent:</Title>
                <Text>
                    {
                        integerFLoat(bankTransfer?.rent)
                    }
                </Text>
            </Space>
            <Divider/>
            <Space style={{justifyContent: "space-between", width: "100%"}}>
                <Title level={5} className='m-0 fw-500'>Expenses:</Title>
                <Text>
                    {
                        integerFLoat(bankTransfer?.expenses)
                    }
                </Text>
            </Space>
            <Divider/>
            <Space style={{justifyContent: "space-between", width: "100%"}}>
                <Title level={5} className='m-0 fw-500'>Our comission:</Title>
                <Text>
                    {
                        bankTransfer?.ourCommission
                    }
                </Text>
            </Space>
            <Divider/>
            <Space style={{justifyContent: "space-between", width: "100%"}}>
                <Title level={5} className='m-0 fw-500'>Airbnb comission:</Title>
                <Text>
                    {
                        integerFLoat(bankTransfer?.airbnbComission)
                    }
                </Text>
            </Space>
            <Divider/>
            <Space style={{justifyContent: "space-between", width: "100%"}}>
                <Title level={5} className='m-0 fw-500'>Bank Transfer:</Title>
                <Text>
                    {
                        bankTransfer?.isEdit ? 0 : bankTransfer?.bankTransfer
                    }
                </Text>
            </Space>
            <Divider/>
            <Space style={{justifyContent: "space-between", width: "100%"}}>
                <Title level={5} className='m-0 fw-500'>Remaining:</Title>
                <Text>
                    {
                       integerFLoat(bankTransfer?.income-(parseInt(bankTransfer?.rent) + parseFloat(bankTransfer?.expenses) + parseInt(bankTransfer?.ourCommission) + parseFloat(bankTransfer?.airbnbComission) + parseInt(bankTransfer?.isEdit ? 0 : bankTransfer?.bankTransfer))) 
                    }
                </Text>
            </Space>
        </div>
        <Divider>Transfer Amount</Divider>
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            onFinish={transferMoney}
            requiredMark={true}
        >
            <Space>
                <MyInput
                    name='amount'
                    label='Amount'
                    size='large'
                    laceholder='select...'
                    style={{width: '200px'}}
                    required
                    validator={({ getFieldValue }) => ({
                        validator(_) {
                            let rem= integerFLoat(bankTransfer?.income-(parseInt(bankTransfer?.rent) + parseFloat(bankTransfer?.expenses) + parseInt(bankTransfer?.ourCommission) + parseFloat(bankTransfer?.airbnbComission) + parseInt(bankTransfer?.isEdit ? 0 : bankTransfer?.bankTransfer)))
                            if (getFieldValue('amount') > rem) {
                                message.error("bank transfer amount should be less or equal to remaining amount")
                                return Promise.reject(new Error('enter correct amount'))
                            }
                            else
                                return Promise.resolve()
                          
                        },
                    })}
                />
                <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={loading}
                    className="trans-down"
                >
                    {bankTransfer?.isEdit ? 'Edit Transfer' : 'Transfer'}
                </Button>
            </Space>
        </Form>
    </Modal>
  );
}

export { BankTransfer };
