import { Card, Col, Row, Typography,Space, Image, theme, Tooltip, Flex } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionsApi } from '../../shared'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
const { useToken } = theme
const { Title, Text} = Typography

const DashboardCards = () => {

    const {t}= useTranslation()
    const dispatch= useDispatch()
    const { token } = useToken()
    const {stats}= useSelector(state=> state?.dashboard)
    const {data : financials}= useSelector(state=> state?.financials)
    const {language}= useSelector(state=> state?.app)
    const user= localStorage.getItem("user")
    const {role}= user ? JSON.parse(user) : {role: null}
    
    const data= role === 'host' ?
    [
        {
            title: 0,
            subtitle: t('Total Properties'),
            img:'properties.png',
        },
        {
            title: 0,
            subtitle: t('Expenses'),
            img:'expenses.png',
        },
        {
            title: 0,
            subtitle: t('Booking'),
            img:'booking.png',
        },
        {
            title: 0,
            subtitle: t('Bank Transfer'),
            img:'mobile-banking.png',
        },
        {
            title: 0,
            subtitle: t('Rent(Monthly)'),
            img:'rent.png',
        },
    ]
    :
    [
        {
            title: 0,
            subtitle: t('Total Properties'),
            img:'properties.png',
        },
        {
            title: 0,
            subtitle: t('Total Clients'),
            img:'clients.png',
        },
        {
            title: 0,
            subtitle: t('Total Revenue'),
            img:'earnings.png',
            info: t("revenue info")
        },
        {
            title: 0,
            subtitle: t('Total Net Revenue'),
            img:'earnings.png',
            info: t("net revenue info")
        },
        {
            title: 0,
            subtitle: t('Total Rent Paid'),
            img:'rent.png',
            info: t("rent info")
        }
    ]
    const [state, setState]= useState([...data])

    useEffect(()=>{
        if(role==='host')
            dispatch(actionsApi?.getFinancials())
        else
            dispatch(actionsApi?.getDashboardStats())
        return ()=>{
            setState([...data])
        }
    }, [role])

    useEffect(()=>{
        if(stats){
            setState([
                {
                    title: stats?.totalProperties,
                    subtitle: t('Total Properties'),
                    img:'properties.png',
                },
                {
                    title: stats?.totalClients,
                    subtitle: t('Total Clients'),
                    img:'clients.png',
                },
                {
                    title: stats?.totalRevenue,
                    subtitle: t('Total Revenue'),
                    img:'earnings.png',
                    info: t("revenue info")
                },
                {
                    title: stats?.totalNetRevenue,
                    subtitle: t('Total Net Revenue'),
                    img:'earnings.png',
                    info: t("net revenue info")
                },
                {
                    title: stats?.totalRentPaid,
                    subtitle: t('Total Rent Paid'),
                    img:'rent.png',
                    info: t('rent info')
                }
            ])
        }
        else if(financials){
            setState([
                {
                    title: financials?.totalProperties,
                    subtitle: t('Total Properties'),
                    img:'properties.png',
                },
                {
                    title: financials?.expenses,
                    subtitle: t('Expenses'),
                    img:'expenses.png',
                },
                {
                    title: financials?.totalBooking,
                    subtitle: t('Booking'),
                    img:'booking.png',
                },
                {
                    title: financials?.moneyTransferFromUS,
                    subtitle: t('Bank Transfer'),
                    img:'mobile-banking.png',
                },
                {
                    title: financials?.totalRent,
                    subtitle: t('Rent(Monthly)'),
                    img:'rent.png',
                }
            ])
        }
        else
            setState([...data])
    }, [stats, financials])
  return (
    <div>
        <Row gutter={[24,24]}>
            {
                state?.map((stat, index)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={'stat-'+index}>
                        <Card 
                            style={{borderRadius: token.borderCardrad,width:"100%"}}
                            className='card-cs shadow-c '
                        >
                            <Flex justify="space-between" align='center' style={{flexDirection: language === 'ar' ?'row-reverse': ''}}>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <Space size={3} align='center' style={{justifyContent :language === 'ar' ? 'flex-end' : 'flex-start'}}>
                                        <Title level={3} className='my-0'>
                                            {stat?.title || 0}
                                        </Title>  
                                        {
                                            stat?.info &&
                                            <Tooltip title={stat?.info}>
                                                <InfoCircleOutlined/> 
                                            </Tooltip>
                                        }    
                                    </Space>                                          
                                    <Text strong>{stat?.subtitle}</Text>
                                </div>
                                <Image preview={false} src={'/assets/images/'+stat?.img} width={'55px'} />
                            </Flex>
                        </Card> 
                    </Col>
                )
            }
        </Row>
    </div>
  )
}

export {DashboardCards}