import ImgCrop from 'antd-img-crop';
import React, { useState, useEffect} from 'react';
import { Upload, Form, Typography} from 'antd';
import "./index.css"
import { apiCalls } from '../../shared/Apis';
import { useSelector } from 'react-redux';

const MyMultipleImageUpload = ({label, name, required, message, form, images}) => {

  const {images: propertyImages}= useSelector(state => state?.property)
  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
    form.setFieldsValue({...form.getFieldsValue, [name]: newFileList?.length ? newFileList?.map(file=> file?.originFileObj || file?.thumbUrl) : null})
  }
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  }
  const onRemove= async (file)=>{
    if(file?.thumbUrl){
      let image= propertyImages?.find(fin => fin?.path === file?.thumbUrl)?.id
      if(image)
      await apiCalls?.property?.deletePropertyImage(image)
    }
  }
  useEffect(()=>{
    if(images?.length)
    {
      let files=[]
      for(let i=0;i<images?.length; i++)
        files?.push({thumbUrl: images[i]?.path})
      setFileList([...files])
    }
    else
    setFileList([])
  }, [images])
  return (
    <Form.Item
      name={name}
      label={<Typography.Text strong>{null}</Typography.Text>}
      rules={[
          {
          required,
          message,
          },
      ]}
      >
        <ImgCrop rotationSlider>
          <Upload
            name={name}
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
            onRemove={onRemove}
          >
            {fileList.length < 5 && '+ Upload'}
          </Upload>
        </ImgCrop>
    </Form.Item>
  );
};
export {MyMultipleImageUpload}