import { Form, Input, Typography } from 'antd';
import { useSelector } from 'react-redux';
export const MyInput = ({withoutForm, name, label, tooltip, type, size, disabled, required, message, value, placeholder, textArea, validator, ...props }) => {
    const {language}= useSelector(state => state?.app)
    return (
        <>
            {
                withoutForm ?
                    textArea ?
                        <Input.TextArea
                            placeholder={placeholder || ''}
                            value={value || ''}
                            {...props}
                        /> :
                    type==='password' ?
                        <Input.Password
                            placeholder={placeholder || ''}
                            value={value || ''}
                            size={size || 'middle'}
                            disabled={disabled || false}
                            {...props}
                            />:
                        <Input
                            type={type || 'text'}
                            placeholder={placeholder || ''}
                            value={value || ''}
                            size={size || 'middle'}
                            disabled={disabled || false}
                            {...props}
                        />
                :
                <Form.Item
                    name={name}
                    label={<Typography.Text strong >{label}</Typography.Text>}
                    tooltip={tooltip || null}
                    labelAlign={language === "ar" ? "right" : 'left'}
                    rules={
                        validator ? [
                            {
                                required: required,
                                message: message,
                            },
                            validator
                        ] : 
                        [
                            {
                                required: required,
                                message: message,
                            }
                        ]
                    }
                >
                    {
                        textArea ?
                            <Input.TextArea
                                placeholder={placeholder || ''}
                                value={value || ''}
                                {...props}
                            /> :
                        type==='password' ?
                            <Input.Password
                                placeholder={placeholder || ''}
                                value={value || ''}
                                size={size || 'middle'}
                                disabled={disabled || false}
                                {...props}
                                />:
                            <Input
                                type={type || 'text'}
                                placeholder={placeholder || ''}
                                value={value || ''}
                                size={size || 'middle'}
                                disabled={disabled || false}
                                {...props}
                            />
                    }
                </Form.Item>

            }
        </>
    )
}