import React, { useState, useEffect } from 'react';
import { useNavigate, Route, Routes, useLocation } from 'react-router-dom'
import { Layout, Menu, Image, Space, Typography } from 'antd'
import { Notifications, UserProfile } from '../components/Header'
import { PropertyDash } from '../pages/PropertyDash'
import { Expense } from './Expens'
import { Client } from './Client'
import {Dashboard} from "./Dashboard"
import {Booking} from "./Booking"
import {Report} from "./Report"
import { Financials } from './Financials'
import './index.css'
import { Employee } from './Employee'
import { DataEntryDrawer, MySelect } from '../components'
import { Host } from './Host';
import { useTranslation } from 'react-i18next';
import { languageLookup } from '../shared/lookups';
import { actionsApi } from '../shared';
import { useDispatch } from 'react-redux';

const { Header, Sider, Content } = Layout;
const { Title, Text } = Typography
const Entry = () => {
  const { t, i18n } = useTranslation()
  const dispatch= useDispatch()
  let navigate = useNavigate();
  const location = useLocation();
  const [ visible, setVisible ] = useState(false)
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([]);
  const [currentTab, setCurrentTab] = useState('1');
  const [openKeys, setOpenKeys] = useState(['sub1'])
  const [language, setLanguage]= useState()
  const user= localStorage.getItem("user")
  const {role}= user ? JSON.parse(user) : {role: null}
  

  function getItem(label, key, icon, children) {
    return { key, icon, children, label };
  }

  useEffect(() => {
    const {role}= JSON.parse(localStorage.getItem("user"))
    if(role){
      let tab = location?.pathname?.split("/")[1];
      tab = role === "host" ?(
        tab === '' ? '1' :
        tab === 'property' || tab==='report' ? '2' :
        tab === 'booking' ? '3' :
        tab === 'expense' ? '4' :
        tab === 'financials' ? '5' :
        tab === 'item9' ? '6' :
        '1'
      ):
      (
        tab === '' ? '1' :
        tab === 'clients'  ? '2' :
        tab === "property" || tab==='report' ? '3' :
        tab === 'booking' ? '4' :
        tab === 'expense' ? '5' :
        tab === 'employee' ? '6' :
        tab === 'item9' ? '7' :
        '1'
      )
      setCurrentTab(tab)
    }
    
  }, [location]);

  useEffect(() => {
    const {role}= JSON.parse(localStorage.getItem("user"))
    if(role){
      let rentalSubMenu= role === "host" ? 
        [ getItem(<Text strong>{t("Dashboard")}</Text>, '1'),  getItem(<Text strong>{t('Property')}</Text>, '2'),  getItem(<Text strong>{t('Booking')}</Text>, '3'),getItem(<Text strong>{t('Expense')}</Text>, '4'), getItem(<Text strong>{t('Financials')}</Text>, '5')] :  
        [ getItem(<Text strong>{t("Dashboard")}</Text>, '1'), getItem(<Text strong>{t('Client')}</Text>, '2'),  getItem(<Text strong>{t('Property')}</Text>, '3'),  getItem(<Text strong>{t('Booking')}</Text>, '4'),getItem(<Text strong>{t('Expense')}</Text>, '5')]
      let itemsArr = role === "host" ? 
        [
          getItem(<Text strong>{t('Daily Rental Management')}</Text>, 'sub1', <img src="/assets/icons/home.png" width={'20px'} alt="" />, [...rentalSubMenu]),
          getItem(<Text strong>{t('Government Transaction Processing')}</Text>, '6', <img src="/assets/icons/gtp.png" width={'20px'} alt="" />)
        ] :
        [
          getItem(<Text strong>{t('Daily Rental Management')}</Text>, 'sub1', <img src="/assets/icons/home.png" width={'20px'} alt="" />, [...rentalSubMenu]),
          getItem(<Text strong>{t('Employee Management')}</Text>, '6', <img src="/assets/icons/tm.png" width={'20px'} alt="" />),
          getItem(<Text strong>{t('Government Transaction Processing')}</Text>, '7', <img src="/assets/icons/gtp.png" width={'20px'} alt="" />)
        ]
      setItems([...itemsArr])
    }
  }, [])
  useEffect(()=>{
    let lang= localStorage.getItem("lang")
    setLanguage(lang  || 'en')
    i18n.changeLanguage(lang  || 'en')
    dispatch(actionsApi?.changeLanguage(lang  || 'en'))
  }, []) 
  const handleMenuClick = (e) => {
    const { key } = e
    const {role}= JSON.parse(localStorage.getItem("user"))
    if(role){
      if(role==="host")
        switch (key) {
          case '1':
            navigate("/", { replace: true });
            break;
          case '2':
            navigate("/property", { replace: true });
            break;
          case '3':
            navigate("/booking", { replace: true });
            break;
          case '4':
            navigate("/expense", { replace: true });
            break;
          case '5':
            navigate("/financials", { replace: true });
            break;
          case '6':
            navigate("/item9", { replace: true });
            break;
          default:
            break;
        }
      else
        switch (key) {
          case '1':
            navigate("/", { replace: true });
            break;
          case '2':
            navigate("/clients", { replace: true });
            break;
          case '3':
            navigate("/property", { replace: true });
            break;
          case '4':
            navigate("/booking", { replace: true });
            break;
          case '5':
            navigate("/expense", { replace: true });
            break;
          case '6':
            navigate("/employee", { replace: true });
            break;
          case '7':
            navigate("/item9", { replace: true });
            break;
          default:
            break;
        }
      }
  }
  const handleChnage= (value)=>{
    setLanguage(value)
    localStorage.setItem("lang", value)
    i18n?.changeLanguage(value)
    window.location.href='/'
  }

 
  const onOpenChange = (keys) => {
    console.log('Dropdown Head',keys)
    setOpenKeys(keys);
  };

  return (
    <Layout style={{ height: '100vh', flexDirection: language==='ar' ? 'row-reverse' : 'row'}}>
      <Sider breakpoint="md"
        collapsedWidth="80"
        onBreakpoint={(broken) => {
          setCollapsed(broken)
        }} trigger={null} collapsible collapsed={collapsed} className={collapsed ? 'addclass' : ''} style={{  height: '100vh', overflowY: 'auto'}}>
        <div className="logo" style={{ display: 'flex', justifyContent: 'center'}}>
          <Image
            style={{ width: '100%'}}
            height={'auto'}
            src= {"./assets/images/logo-2.png"}
            alt='Rental Admin Panel'
            preview={false}
          />
        </div>
        <Menu
          defaultSelectedKeys={['1']}
          selectedKeys={[currentTab]}
          mode="inline"
          theme="dark"
          onClick={handleMenuClick}
          onOpenChange={onOpenChange}
          openKeys={openKeys}
          items={items}
          className='listitem'
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background header-mbl-cs shadow-c"
          style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <div style={{ width: '98%', display: 'flex', justifyContent: 'space-between', flexDirection: language==="ar" ? 'row-reverse' : 'row'}}>
            <Space align='center' size={8} style={{flexDirection: language==="ar" ? 'row-reverse' : 'row'}}>
              <div className='trigger border-0-btn' onClick={() => setCollapsed(!collapsed)}>
                {
                  collapsed ?
                  <Image src={language === "ar" ? '/assets/icons/collapse.png' : '/assets/icons/expand.png'} width={'25px'} preview={false} />:
                  <Image  src={language === "ar" ? '/assets/icons/expand.png' : '/assets/icons/collapse.png'} width={'25px'} preview={false} />
                }
              </div>
              <Title level={5} className='my-0'>
                {
                  role==='Host' ?
                  t("Welcome to Host Panel") :
                  t("Welcome to Admin Panel") 
                }
              </Title>
            </Space>
            <Space size={15} style={{flexDirection: language==="ar" ? 'row-reverse' : 'row'}}>
              <MySelect
                withoutForm
                value={language}
                options={languageLookup}
                onChange={handleChnage}
              />
              <Notifications />
              <UserProfile />
            </Space>
          </div>
        </Header>
        <Content
          // className="scroll-bar width-fix"
          className="scroll-bar"
          style={{
            padding: '10px 13px',
            minHeight: 280,
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/property" element={<PropertyDash />} />
            <Route path="/expense" element={<Expense />} />
            <Route path="/clients" element={<Client />} />
            <Route path="/report">
              <Route 
                path=":propertyID" 
                element={<Report />} 
              />
              <Route 
                path=":propertyID/client/:clientID" 
                element={<Report />} 
              />
            </Route>
            <Route path="/employee" element={<Employee />} />
            <Route path="/financials" element={<Financials />} />
          </Routes>
          <DataEntryDrawer 
            visible={visible}
            onClose={()=>setVisible(false)}
          />
        </Content>
      </Layout>
    </Layout>
  );
}

export default Entry