import { message } from "antd";
import { checkAuthorization } from "../../helpers";
import { domainUrl } from "../../constants";

const addUpdateBooking = (formData) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    }
    let url = formData.get("id") ? '/updateBooking' :'/storeBooking'
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error || 'Something went wrong');
            return 0
        })
    )
}
const addMultipleBooking = (data) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: 'follow'
    }
    return (
        fetch(domainUrl + "/uploadMultipleBooking", requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error || 'Something went wrong');
            return 0
        })
    )
}
const checkingBooking = (payload) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    myHeaders.append('content-type', "application/json")
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: 'follow'
    }
    return (
        fetch(domainUrl + '/checkBooking', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                if(result?.data){
                    message.error('booking found for selected nights')
                    return 2
                }
                return 1
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error || 'Something went wrong');
            return 0
        })
    )
}
const deleteBooking= (Id)=>{
    const {userToken} = checkAuthorization();
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    return(
        fetch(domainUrl + `/deleteBooking/${Id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
  }

const booking = {
    addUpdateBooking,
    addMultipleBooking,
    checkingBooking,
    deleteBooking
}

export {booking}