import { Modal, Button, Space } from "antd"
import { Page, Text, View, Document, StyleSheet, usePDF, Font} from "@react-pdf/renderer"
import { useSelector } from "react-redux"
import { simpleDateFormat, simpleDateFormatUtcToLocal } from "../../../shared"
import { useTranslation } from "react-i18next";
import { rentPeriod } from "../../../shared/lookups"

Font.register({
  family: "Noto Naskh Arabic",
  src:'https://fonts.gstatic.com/ea/notonaskharabic/v3/NotoNaskhArabic-Regular.ttf'
})

const GeneratePdfReport = ({ visible, onClose }) => {

  const {t}= useTranslation()
  const {data, expense, booking, rent, notes}= useSelector(state => state?.report)
  const {language}= useSelector(state => state?.app)
  const MyDocument = () => (
    <Document>
      <Page style={styles.page} size='A3'>
        <View>
          <View style={styles.header}>
            <Text style={styles.title}>{t("Property Report")}</Text>
            {
              language === "ar" ?
              <Text style={styles.subtitle}>{new Date().toLocaleDateString() +" :"+ t("Generated on")}</Text>:
              <Text style={styles.subtitle}>{t("Generated on")}: {new Date().toLocaleDateString()}</Text>
            }
          </View>
        </View>
        <View style={styles.section}>
          <View style={language === "ar" ? styles.textEnd : null}>
            <Text 
              style={styles.sectionTitle} 
            >
              {t("OverView")}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={language === "ar" ? {...styles?.tableRow,...styles?.textEnd} : styles?.tableRow}>
                <Text style={styles.tableHeader}>
                  {t("Income")}
                </Text>
                <Text style={styles.tableHeader}>
                  {t("Rent")}
                </Text>
                <Text style={styles.tableHeader}>
                  {t("Expense")}
                </Text>
                <Text style={styles.tableHeader}>
                  {t("Our Comission")}
                </Text>
                <Text style={styles.tableHeader}>
                  {t("Airnb Comission")}
                </Text>
                <Text style={styles.tableHeader}>
                  {t("Total Booking")}
                </Text>
                <Text style={styles.tableHeader}>
                  {t("Bank Transfer")}
                </Text>
                <Text style={styles.tableHeader}>
                  {t("Remaining")}
                </Text>
            </View>
            <>
              {
                data?.map((row, index)=> (
                  <View style={language === "ar" ? {...styles?.tableRow,...styles?.textEnd} : styles?.tableRow} key={'overview-'+index}>
                    <Text style={styles.tableCell}>
                      {row?.income}
                    </Text>
                    <Text style={styles.tableCell}>
                      {row?.rent}
                    </Text>
                    <Text style={styles.tableCell}>
                      {(row?.expenses)}
                    </Text>
                    <Text style={styles.tableCell}>
                      {row?.ourCommission}
                    </Text>
                    <Text style={styles.tableCell}>
                      {(row?.airbnbComission)}
                    </Text>
                    <Text style={styles.tableCell}>
                      {row?.totalBooking}
                    </Text>
                    <Text style={styles.tableCell}>
                      {row?.bankTransfer}
                    </Text>
                    <Text style={styles.tableCell}>
                      {row?.income-(parseInt(row?.rent) + parseFloat(row?.expenses) + parseInt(row?.ourCommission) + parseFloat(row?.airbnbComission) + parseInt(row?.bankTransfer))}
                    </Text>
                  </View>
                ))
              }
            </>
          </View>
        </View>
        <View style={styles.section}>
          <View style={language === "ar" ? styles.textEnd : null}>
            <Text 
              style={styles.sectionTitle} 
            >
              {t("Booking")}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={language === "ar" ? {...styles?.tableRow,...styles?.textEnd} : styles?.tableRow}>
                <Text style={styles.tableHeader}>
                  {t("Property Name")}
                </Text>
                <Text style={styles.tableHeader}>
                  {t("Client Name")}
                </Text>
                <Text style={styles.tableHeader}>
                  {t("Guest Name")}
                </Text>
                <Text style={styles.tableHeader}>
                  {t("Number of Nights")}
                </Text>
                <Text style={styles.tableHeader}>
                  {t("Check-in date")}
                </Text>
                <Text style={styles.tableHeader}>
                 {t("Check-out date")}
                </Text>
            </View>
            <>
              {
                booking?.map((row, index)=> (
                  <View style={language === "ar" ? {...styles?.tableRow,...styles?.textEnd} : styles?.tableRow} key={'booking-'+index}>
                    <Text style={styles.tableCell}>
                      {row?.propertyName}
                    </Text>
                    <Text style={styles.tableCell}>
                      {row?.clientName}
                    </Text>
                    <Text style={styles.tableCell}>
                      {row?.name}
                    </Text>
                    <Text style={styles.tableCell}>
                      {row?.numberOFnights}
                    </Text>
                    <Text style={styles.tableCell}>
                      {row?.checkINDate}
                    </Text>
                    <Text style={styles.tableCell}>
                      {row?.checkOutDate}
                    </Text>
                  </View>
                ))
              }
            </>
          </View>
        </View>
        <View style={styles.section}>
          <View style={language === "ar" ? styles.textEnd : null}>
            <Text 
              style={styles.sectionTitle} 
            >
              {t("Expense")}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={language === "ar" ? {...styles?.tableRow,...styles?.textEnd} : styles?.tableRow}>
                <Text style={styles.tableHeader}>
                  {t("Expense Name")}
                </Text>
                <Text style={styles.tableHeader}>
                  {t("Amount (SAR)")}
                </Text>
                <Text style={styles.tableHeader}>
                  {t("Time Stamp")}
                </Text>
            </View>
            <>
              {
                expense?.map((row, index)=> (
                  <View style={language === "ar" ? {...styles?.tableRow,...styles?.textEnd} : styles?.tableRow} key={'expense-'+index}>
                    <Text style={styles.tableCell}>
                      {row?.name}
                    </Text>
                    <Text style={styles.tableCell}>
                      {row?.amount}
                    </Text>
                    <Text style={styles.tableCell}>
                      {simpleDateFormatUtcToLocal(row?.created_at)}
                    </Text>
                  </View>
                ))
              }
            </>
          </View>
        </View>
        {
          rent?.length ?
          <View style={styles.section}>
            <View style={language === "ar" ? styles.textEnd : null}>
              <Text 
                style={styles.sectionTitle} 
              >
                {t("Rent")}
              </Text>
            </View>
            <View style={styles.table}>
              <View style={language === "ar" ? {...styles?.tableRow,...styles?.textEnd} : styles?.tableRow}>
                  <Text style={styles.tableHeader}>
                    {t("Rent amount")}
                  </Text>
                  <Text style={styles.tableHeader}>
                    {t("Rent per month")}
                  </Text>
                  <Text style={styles.tableHeader}>
                    {t("Rent period")}
                  </Text>
                  <Text style={styles.tableHeader}>
                    {t("Contract date")}
                  </Text>
              </View>
              <>
                {
                  rent?.map((row, index)=> (
                    <View style={language === "ar" ? {...styles?.tableRow,...styles?.textEnd} : styles?.tableRow} key={'rent-'+index}>
                      <Text style={styles.tableCell}>
                        {row?.rentAmount}
                      </Text>
                      <Text style={styles.tableCell}>
                        {row?.rent}
                      </Text>
                      <Text style={styles.tableCell}>
                        {
                          rentPeriod?.find(fin => fin?.id === parseInt(row?.rentPeriod))?.name
                        }
                      </Text>
                      <Text style={styles.tableCell}>
                        {simpleDateFormat(row?.contractDate)}
                      </Text>
                    </View>
                  ))
                }
              </>
            </View>
          </View>
          :
          <></>
        }
        {
          notes?.length ?
          <View style={styles.section}>
            <View style={language === "ar" ? styles.textEnd : null}>
              <Text 
                style={styles.sectionTitle} 
              >
                {t("Notes")}
              </Text>
            </View>
            <View style={styles.table}>
              <View style={language === "ar" ? {...styles?.tableRow,...styles?.textEnd} : styles?.tableRow}>
                  <Text style={styles.tableHeader}>
                    {t("Note")}
                  </Text>
              </View>
              <>
                {
                  notes?.map((row, index)=> (
                    <View style={language === "ar" ? {...styles?.tableRow,...styles?.textEnd} : styles?.tableRow} key={'note-'+index}>
                      <Text style={styles.tableCell}>
                        {row?.note}
                      </Text>
                    </View>
                  ))
                }
              </>
            </View>
          </View>
          :
          <></>
        }
      </Page>
    </Document>
  );
  

  const [instance, update] = usePDF({ document: <MyDocument /> });

  return (
    <Modal
      title={null}
      open={visible}
      onOk={onClose} 
      onCancel={onClose}
      width={1000}
      closeIcon={false}
      centered
      footer={
        <Space className="w-100 right">
          <Button
            block
            className="btnsave"
            type="primary"
            htmlType="submit"
            onClick={() => {
              update();
              if (instance.url) {
                window.open(instance.url);
              }
            }}
          >
            PDF
          </Button>
        </Space>
      }
    >
      <MyDocument />
    </Modal>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: "Noto Naskh Arabic",
    width:'100%',
    
  },
  header: {
    marginBottom: 20,
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
  },
  title: {
    fontSize: '16pt',
    fontWeight: "bold",
    marginBottom: "5pt",
  },
  subtitle: {
    fontSize: 12,
    color: "gray",
  },
  section: {
    marginBottom: 20,
    display:'flex',
    flexDirection:'column',
  },
  sectionTitle: {
    fontSize: '12pt',
    marginBottom: '8pt',
    fontWeight: 500
  },
  table: {
    display: "flex",
    width: "100%",
    flexDirection:'column'
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1px solid #eee",
    justifyContent:'space-around',
    display:'flex',
  },
  tableHeader: {
    flex: 1,
    fontWeight: 600,
    fontSize: '10pt',
    color: "#333",
    backgroundColor:'#F5F5F5',
    padding: 8,
  },
  tableCell: {
    flex: 1,
    fontSize: '10pt',
    padding: 8,
    fontWeight: 400
  },
  image: {
    width: 100, 
    height: 50, 
    marginBottom: 20,
  },
  textEnd:{
    textAlign: "end"
  }
});

export { GeneratePdfReport };
