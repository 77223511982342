import React, { useEffect, useState } from 'react'
import { Card, Dropdown, Form, Flex, Button, Row, Col } from 'antd'
import ReactApexChart from 'react-apexcharts';
import { DownOutlined, FilterOutlined } from '@ant-design/icons';
import { ModuleTopHeading } from '../PageComponents';
import { useDispatch, useSelector } from 'react-redux';
import { actionsApi } from '../../shared';
import { useTranslation } from 'react-i18next';

const ComparisonChart = () => {

  const {t}= useTranslation()
  const dispatch= useDispatch()
  const {comparisonGraph}= useSelector(state=> state?.dashboard)
  const {language}= useSelector(state=> state?.app)
  console.log("comp:", comparisonGraph)
  useEffect(()=>{
      // dispatch(actionsApi?.getComparisonGraph())
  }, [])
  const chartData = {
    series: [
      {
        name: t('Previous year'),
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 22, 11, 7]
      },
      {
        name: t('Current year'),
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 11, 77,3]
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['transparent']
      },
      xaxis: {
        categories: [t("January"), t('February'), t('March'), t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t("November"), t("December")]
      },
      fill: {
        opacity: 1
      },
      grid: {
        show: false
      },
      colors: ['#DFCCC8', '#B27777']
    }
  };

  return (
    <div>
      <Card className='shadow-c border0 radius-12'>
        <Row gutter={16}>
            <Col span={24} style={{display:'flex', justifyContent:language === 'ar' ? 'flex-end' : 'flex-start'}}>
                <ModuleTopHeading name={t('Income Comparison')} /> 
            </Col>
            <Col span={24}>
                <ReactApexChart 
                    options={chartData.options} 
                    series={chartData.series} 
                    type="bar" 
                    height={350} 
                />
            </Col>
        </Row>
      </Card>
    </div>
  )
}

export { ComparisonChart }
