import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { message } from 'antd'
import { checkAuthorization } from '../../../helpers'

export const getAllEmployee = createAsyncThunk('employee',
    async (object, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        dispatch(getting())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({name: object?.name, order: ""})
        }
        fetch(domainUrl + "/viewAllEmployees", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success){
                    dispatch(employeeResponse(result?.data))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                message.error(error)
                dispatch(employeeClear()) 
            })
    }
)


const EmployeeSlice = createSlice({
    name: 'employee',
    initialState: {
        data: [],
        loading: false,
        pagination:{
            pageNo:1,
            pageSize: 20,
            totalRecords:0,
        },
        filters: {
            name: ''
        },
    },
    reducers: {
        getting: state => {
            state.loading = true
        },
        employeeResponse: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        employeeClear: state => {
            state.data = []
            state.loading = false
        },
        changeEmployeeFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    }
})

export const { getting, employeeResponse, employeeClear, changeEmployeeFilters, setPages} = EmployeeSlice.actions;
export default EmployeeSlice.reducer;