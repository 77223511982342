import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space} from "antd"
import { MyInput } from "../../Forms"
import { apiCalls } from "../../../shared/Apis"
import { useParams } from "react-router-dom"

const AddEditNote= ({visible, onClose, getPayload, editNote, callBack}) => {
  
    const [form] = Form.useForm()
    const {propertyID}= useParams()
    const [ loading, setLoading ] = useState(false)  
    
    useEffect(() => {
        if (visible && editNote) {
            form.setFieldsValue({...editNote})
        } else {
            form.resetFields();
        }
    }, [visible, editNote])

    const addUpdateNote= async ()=>{
        setLoading(true)
        let data= form.getFieldsValue()
        const {year, month}= getPayload()
        data={
            id: editNote?.id,
            propertyID,
            month,
            year,
            ...data
        }
        let result= await apiCalls?.notes?.addUpdateNote(data)
        if(result){
            callBack()
            closeDrawer()
        }
        setLoading(false)
    }
    const closeDrawer= ()=>{
        form.resetFields()
        onClose()
    }
  return (
    <Drawer
        title={editNote ? 'Edit note' : 'Add note'}
        onClose={closeDrawer}
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {editNote ? 'Update note' : 'Save note'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            onFinish={addUpdateNote}
            requiredMark={true}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <MyInput
                        textArea
                        label='Note'
                        name='note'
                        placeholder='e.g hi...'
                        required
                        rows={5}
                        message='Please enter note'
                    />
                </Col>               
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditNote}