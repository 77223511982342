function convertTo12HourFormat(time) {
    var parts = time.split(":");
    var hours = parseInt(parts[0]);
    var minutes = parseInt(parts[1]);
    var suffix = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    var formattedTime = hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0") + " " + suffix;
    return formattedTime;
  }
function formatCurrency(number) {
    number= Number(number)
    // const formattedCurrency = number.toLocaleString('en-PK', {
    //   style: 'currency',
    //   currency: 'PKR'
    // })
      // Ensure the input is a valid number
  if (typeof number !== 'number')
    return 'PKR 0'
  const integerPart = Math.floor(number)
  const formattedIntegerPart = integerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `PKR ${formattedIntegerPart}`
  }
function formatPhoneNumberPK(phoneNumber) {
    phoneNumber = phoneNumber.replace(/\D/g, '')
    if (phoneNumber === '' || phoneNumber.length < 10)
      return phoneNumber
    phoneNumber = '+92 ' + phoneNumber.substr(1, 2) + '-' + phoneNumber.substr(3)
    return phoneNumber
  }
function generateRandomAlphanumeric() {
  const alphanumericCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * alphanumericCharacters.length);
    randomString += alphanumericCharacters.charAt(randomIndex);
  }

  return randomString;
}  
function integerFLoat(value) {
  if (typeof value !== 'number' || isNaN(value)) 
    return 0
  if (Number.isInteger(value))
    return value
  else 
    return value.toFixed(2)
}

  
export {
    convertTo12HourFormat,
    formatCurrency,
    formatPhoneNumberPK,
    generateRandomAlphanumeric,
    integerFLoat
}