import { Flex, Typography } from "antd";
import { simpleDateFormat } from "../../shared";
import { ColumnName } from "../ColumnName";
const {Text}= Typography
let lang= localStorage.getItem("lang")
lang= lang || 'en'
export const expenseColumns=[
    {
        title: 
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Expense Name"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'name',
        align: lang === 'ar' ? 'right' : 'left'
    },
    {
        title: 
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Amount (SAR)"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'amount',
        align: lang === 'ar' ? 'right' : 'left'
    },
    // {
    //     title: 
    //         <Flex align='center' gap={4}>
    //             <Text >
    //                 Added By
    //             </Text>
    //             <img src="/assets/icons/tb-ar.png" width={18} alt="" />
    //         </Flex>,
    //     dataIndex: 'addedBy',
    //align: lang === 'ar' ? 'right' : 'left'
    // },
    {
        title: 
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Expense date"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'date',
        align: lang === 'ar' ? 'right' : 'left',
        render: col => <Text>{simpleDateFormat(col)}</Text>
    }
]