import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Typography } from 'antd'
import { ImagePreviewModal } from './ImagePreviewModal'
import { MyMultipleImageUpload } from '../Forms'
import { apiCalls } from '../../shared/Apis'
import { useDispatch, useSelector } from 'react-redux'
import { actionsApi } from '../../shared'


const UploadImageModal = ({ visible, onClose, appData}) => {

  const dispatch= useDispatch()
  const [form] = Form.useForm()
  const {images}= useSelector(state => state?.property)
  const [loading, setLoading] = useState(false)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')

  useEffect(()=>{
    if(visible?.propertyID)
      dispatch(actionsApi?.getAllPropertyImages(visible?.propertyID))
  }, [visible])

  const addUpdatePropertyImages= async ()=>{
    setLoading(true)
    let data=form.getFieldsValue()
    let formData = new FormData()
    formData.append(`id`, visible?.propertyID)
    for(let i=0;i<data?.images?.length;i++)
      {
        if(typeof data?.images[i]==='string')
          formData.append(`oldUrls[]`, data?.images[i])
        else
          formData.append(`multipleImages[]`, data?.images[i])
      }
    const result= await apiCalls?.property?.addUpdatePropertyImages(formData)
    setLoading(false)
    if(result)
      onClose()
  }
  const closePreview = () => {
    setPreviewVisible(false)
    setPreviewImage('')
  }

  return (
    <>
      <Modal
        open={visible}
        width={650}
        centered
        className='shadow-c'
        title={<Typography.Text className="ant-modal-title">Add Images</Typography.Text>}
        onCancel={onClose}
        footer={[
          <Button
            key="submit"
            className='btnsave'
            type="primary"
            loading={loading}
            onClick={()=> form.submit()}
          >
            Save Images
          </Button>
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={true}
          onFinish={addUpdatePropertyImages}
        >
          <MyMultipleImageUpload
            name='images'
            label='Add property images'
            required
            message='please upload images'
            form={form}
            images={images}
          />
        </Form>
      </Modal>
      <ImagePreviewModal
        visible={previewVisible}
        imageSrc={previewImage}
        onClose={closePreview}
      />
    </>
  )
}

export { UploadImageModal }
