import { Flex } from "antd"
import { simpleDateFormat } from "../../shared"
import { ColumnName } from "../ColumnName"
import { rentPeriod } from "../../shared/lookups"

let lang= localStorage.getItem("lang")
lang= lang || 'en'

export const rentColumn=[
    {
        title: 
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Rent amount"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'rentAmount',
        align: lang === 'ar' ? 'right' : 'left'
    },
    {
        title:
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Rent per month"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'rent',
        align: lang === 'ar' ? 'right' : 'left'
    }, 
    {
        title: 
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Rent period"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'rentPeriod',
        align: lang === 'ar' ? 'right' : 'left',
        render: col => rentPeriod?.find(fin => fin?.id === parseInt(col))?.name
    },
    {
        title: 
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Contract date"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'contractDate',
        align: lang === 'ar' ? 'right' : 'left',
        render: col => simpleDateFormat(col)
    }
]