import React from 'react'
import { TableDashboard } from '../../components'
import { Space } from 'antd'
import { ComparisonChart, DashboardCards, PropertyOverView } from '../../components/Dashboard'

const Dashboard = () => {
  const user= localStorage.getItem("user")
  const {role}= user ? JSON.parse(user) : {role: null}
  return (
    <Space direction='vertical' size={20} className='w-100'>
        <DashboardCards/>
        {
          role!=='host' &&
          <ComparisonChart />
        }
        <PropertyOverView/>
    </Space>
  )
}

export {Dashboard}