import { configureStore } from "@reduxjs/toolkit";
import {
	login,
	loginCheck,
	addandbox,
	mastervendor,
	client,
	property,
	booking,
	report,
	expense,
	employee,
	host,
	app,
	dashboard,
	financials
} from "./action"
const store = configureStore({
	reducer: {
		login,
		loginCheck,
		addandbox,
		mastervendor,
		client,
		property,
		booking, 
		report,
		expense,
		employee,
		host,
		app,
		dashboard,
		financials
	},
})
export default store