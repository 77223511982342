import { addBoxApi } from './AndroidBoxApi'
import { vendorApi } from './VendorApi'
import { MasterportalApi } from './MasterportalApi'
import { ChangePassApi } from './ChangePassApi'
import { ProfileApi } from './ProfileApi'
import { client } from './client'
import { property } from './property'
import { booking } from './booking'
import { expense } from './expense'
import { rent } from './rent'
import { employee } from './employee'
import { dataentry } from './dataentry'
import { hostapi } from './hostapi'
import { bankTransfer } from './bankTransfer'
import { notes } from './notes'
import {user} from "./user"
const apiCalls={
    addBoxApi,
    vendorApi,
    MasterportalApi,
    ChangePassApi,
    ProfileApi,
    client,
    property,
    booking,
    expense,
    rent,
    employee,
    dataentry,
    hostapi,
    bankTransfer,
    user,
    notes
}
export {apiCalls}