import React, { useEffect, useState } from "react"
import { Drawer, Button, Col, Row, Form, Divider} from "antd"
import { checkAuthorization, domainUrl } from "../../../shared"
import { MyInput } from "../../Forms"
import "./index.css"
import { apiCalls } from "../../../shared/Apis"
import { useTranslation } from "react-i18next"

const UserProfileDrawer= ({visible, onClose})=>{

    const {t}= useTranslation()
    const [form]= Form.useForm()
    const [loading, setLoading]= useState(false)
    const [passwordLoading, setPasswordLoading]= useState(false)

    useEffect(()=>{
        let user= localStorage.getItem("user")
        if(user){
            user= JSON.parse(user)
            form.setFieldsValue({...user})
        }
    }, [])
    const logout = () => {
        setLoading(true)
        const {userToken}= checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        }
        fetch(domainUrl + '/logout', requestOptions)
        .then(response => response.json())
        .then(result => {
        if (result?.success)
            {
                localStorage.clear()
                window.location.href = '/'
            }
        else
            throw 'error'
        })
        .catch(() => {
            setLoading(false)
            localStorage.clear()
            window.location.href = '/'
        })
    }
    const changePassword= async ()=>{
        setPasswordLoading(true)
        const data= form.getFieldsValue()
        let result= await apiCalls?.user?.changePassword(data)
        if(result)
            logout()
        setPasswordLoading(false)

    }

    return (
        <Drawer
            title={t('Profile edit')}
            onClose={onClose}
            open={visible}
            width={550}
            footer={
                <Button  
                    block
                    danger
                    type="primary"
                    loading={loading}
                    onClick={logout}
                    size="large"
                >
                    {t("Logout")}
                </Button>
            }
            className="user-profile-drawer"
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                onFinish={changePassword}
                requiredMark={true}
            >
                <Row gutter={16}>  
                    <Col span={12}>
                        <MyInput
                            autoFocus
                            label={t('Name')}
                            name='name'
                            size='large'
                        />
                    </Col>  
                    <Col span={12}>
                        <MyInput
                            autoFocus
                            label={t('Role')}
                            name='role'
                            size='large'
                        />
                    </Col>  
                    <Col span={24}>
                        <MyInput
                            autoFocus
                            label={t('Email')}
                            name='email'
                            size='large'
                        />
                    </Col>  
                    <Divider/> 
                    <Col span={24}>
                        <MyInput
                            autoFocus
                            label={t('Old password')}
                            name='oldPassword'
                            placeholder='e.g Naj@67SS'
                            size='large'
                            required
                            message={t('Please enter password')}
                            type='password'
                        />
                    </Col>   
                    <Col span={24}>
                        <MyInput
                            label={t('New password')}
                            name='newPassword'
                            placeholder='e.g Naj@67SS'
                            size='large'
                            required
                            validator={({ getFieldValue }) => ({
                                validator(_) {
                                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                                    if(passwordRegex.test(getFieldValue("newPassword")))
                                        return Promise.resolve()
                                    else
                                        return Promise.reject(new Error(t("password validation")))
                                }
                            })} 
                            type='password'
                        />
                    </Col>     
                    <Col span={24} className="justify-end">
                        <Button
                            type="primary"
                            size="large"
                            htmlType="html"
                            loading={passwordLoading}
                        >
                            {t("Change password")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}
export default UserProfileDrawer