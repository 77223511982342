import {Modal, Space, Typography, Divider} from 'antd'
import "./index.css"
import { useState } from 'react'
import { propertyOwnership } from '../../../shared/lookups'
import { onlyDate } from '../../../shared'
const { Title, Text, Link} = Typography

const ViewProperty = ({property, onClose}) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const openFile= (file)=>{
        let extension= file?.split('.').pop().split(/\#|\?/)[0]
        if(['pdf', 'txt', 'doc', 'docx']?.includes(extension)){
            let pdfWindow = window.open("")
            pdfWindow.document.write(`<object data="${file}" type="application/pdf" width="100%" height="100%">Your browser does not support embedded PDF files.</object>`)
        }
        else if(['jpg','jpeg','png']?.includes(extension)){
            setPreviewOpen(true)
            setPreviewImage(file)
        }
    }
  return (
    <div>
        <Modal
            title={ property?.propertyName+"'s Detail"}
            centered
            width={700}
            visible={property}
            onOk={onClose}
            onCancel={onClose}
            footer={null}
            className='property-view-modal'
        >
            <div className='py-3'>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>Client:</Title>
                    <Text>
                        {
                            property?.client
                        }
                    </Text>
                </Space>
                <Divider/>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>Address:</Title>
                    <Text>
                        {
                            property?.address
                        }
                    </Text>
                </Space>
                <Divider/>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>City:</Title>
                    <Text>
                        {
                            property?.city
                        }
                    </Text>
                </Space>
                <Divider/>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>Neighborhood:</Title>
                    <Text>
                        {
                            property?.neighborhood
                        }
                    </Text>
                </Space>
                <Divider/>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>Property Type:</Title>
                    <Text>
                        {property?.propertyType}
                    </Text>
                </Space>
                <Divider/>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>Ownership:</Title>
                    <Text>
                        {
                            propertyOwnership.find(fin => fin?.id === parseInt(property?.ownerShip))?.name
                        }
                    </Text>
                </Space>
                <Divider/>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>Bedroom:</Title>
                    <Text>
                        {
                            property?.bedrooms
                        }
                    </Text>
                </Space>
                <Divider/>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>Bathroom:</Title>
                    <Text>
                        {
                            property?.bathrooms
                        }
                    </Text>
                </Space>
                <Divider/>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>Furnished:</Title>
                    <Text>
                        {
                            property?.furnished
                        }
                    </Text>
                </Space>
                {
                    parseInt(property?.ownerShip) === 2 && 
                    <>
                        <Divider/>
                        <Space style={{justifyContent: "space-between", width: "100%"}}>
                            <Title level={5} className='m-0 fw-500'>Owner name:</Title>
                            <Text>
                                {
                                    property?.ownerName || '-'
                                }
                            </Text>
                        </Space>
                        <Divider/>
                        <Space style={{justifyContent: "space-between", width: "100%"}}>
                            <Title level={5} className='m-0 fw-500'>Owner email:</Title>
                            <Text>
                                {
                                    property?.ownerEmail || '-'
                                }
                            </Text>
                        </Space>
                        <Divider/>
                        <Space style={{justifyContent: "space-between", width: "100%"}}>
                            <Title level={5} className='m-0 fw-500'>Owner Contact Number:</Title>
                            <Text>
                                {
                                    property?.ownerContactNumber || '-'
                                }
                            </Text>
                        </Space>
                        <Divider/>
                        <Space style={{justifyContent: "space-between", width: "100%"}}>
                            <Title level={5} className='m-0 fw-500'>Owner Contract:</Title>
                            <Link onClick={()=> openFile(property?.ownerContractName)}>
                                {
                                    property?.ownerContract || '-'
                                }
                            </Link>
                        </Space>
                        <Divider/>
                        <Space style={{justifyContent: "space-between", width: "100%"}}>
                            <Title level={5} className='m-0 fw-500'>Addition info:</Title>
                            <Text>
                                {
                                    property?.addtionalInfo || '-'
                                }
                            </Text>
                        </Space>
                        <Divider/>
                        <Space style={{justifyContent: "space-between", width: "100%"}}>
                            <Title level={5} className='m-0 fw-500'>Rent amount:</Title>
                            <Text>
                                {
                                    property?.rentAmount || '0'
                                }
                            </Text>
                        </Space>
                        <Divider/>
                        <Space style={{justifyContent: "space-between", width: "100%"}}>
                            <Title level={5} className='m-0 fw-500'>Rent due date:</Title>
                            <Text>
                                {
                                    onlyDate(property?.rentDate)
                                }
                            </Text>
                        </Space>
                        <Divider/>
                        <Space style={{justifyContent: "space-between", width: "100%"}}>
                            <Title level={5} className='m-0 fw-500'>Additional info(Rent):</Title>
                            <Text>
                                {
                                    onlyDate(property?.addtionalRentInfo)
                                }
                            </Text>
                        </Space>
                    </>
                }
            </div>
        </Modal>
        <Modal 
            open={previewOpen} 
            title={'Preview'} 
            footer={null} 
            onCancel={()=>{setPreviewImage(null); setPreviewOpen(null)}}
        >
            <img alt="najla-rental" style={{ width: '100%' }} src={previewImage} />
        </Modal>
    </div>
  )
}
export {ViewProperty}