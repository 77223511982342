import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { message } from 'antd'
import { checkAuthorization } from '../../../helpers'

export const getFinancials = createAsyncThunk('financials',
    async (_, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        dispatch(getting())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + "/financial", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success)
                    dispatch(financialsResponse(result))
                else 
                    throw result?.message
            })
            .catch(error => { 
                message.error(error)
                dispatch(financialsResponse(null)) 
            })
    }
)

const FinancialsSlice = createSlice({
    name: 'financials',
    initialState: {
        data: null,
        loading: false,
    },
    reducers: {
        getting: state => {
            state.loading = true
        },
        financialsResponse: (state, action) => {
            state.data = action.payload
            state.loading = false
        }
    }
})

export const { getting, financialsResponse} = FinancialsSlice.actions;
export default FinancialsSlice.reducer;