import React,{ useState } from 'react'
import { Row, Col, Typography, Table, Space, Button } from 'antd'
import { ActionButton, ModuleTopHeading } from '../../PageComponents';
import { AlertModal } from '../../AlertModal';
import { AddEditBookingDrawer } from '../../BookingComponents/AddEditBookingDrawer';
import { useSelector } from 'react-redux';
import { bookingColumns } from '../../../data/columns';
import { TableLoader } from '../../../shared';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Text } = Typography

const BookingReportTable = () => {

    const {t}= useTranslation()
    const {booking, loadingBooking, bookingPagination}= useSelector(state => state?.report)
    const {language}= useSelector(state => state?.app)
    const [ visible, setVisible ] = useState(false)
    const [ editbook, setEditBook ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [ reportonly, setReportOnly ] = useState(true)
    const user= localStorage.getItem("user")
    const {role}= user ? JSON.parse(user) : {role: null}

    const columns = [
        ...bookingColumns,
        role !=='host' ?
            {
                title: <Text strong>{t("Action")}</Text>,
                key: 'action',
                fixed: 'right',
                align: language === 'ar' ? 'right' : 'left',
                render: (_, row) => (
                    <Space> 
                        <ActionButton
                            title={t('Edit booking')}
                            shape="circle"
                            icon={<EditOutlined/>}
                            onClick={() => { setVisible(true); setEditBook(true)}}
                        />
                        <ActionButton
                            title={t('Delete booking')}
                            icon={<DeleteOutlined/>}
                            danger
                            onClick={() => { setDeleteItem({name:row?.guestName}) }}
                        />
                    </Space>
                ),
            }
        :
            {}
    ]
  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <ModuleTopHeading 
                    name={t('Booking Details')}
                    onClick={()=>{setVisible(true);setReportOnly(true)}}
                />
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={booking} 
                    scroll={{x: "max-content"}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    pagination={{
                        hideOnSinglePage: true,
                        total: 12,
                        pageSize: bookingPagination?.pageSize,
                        defaultPageSize: bookingPagination?.pageSize,
                        current: bookingPagination?.pageNo,
                        size: "default",
                        pageSizeOptions: ['10', '20', '50', '100'],
                        //onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        showTotal: (total) => <Button className='bg-secondary-color border0'>Total: {total}</Button>,
                    }}
                    loading={
                        {
                            ...TableLoader,
                            spinning: loadingBooking
                        }
                    }
                />
            </Col>
        </Row>
        <AddEditBookingDrawer
            visible={visible}
            editbook={editbook}
            reportonly={reportonly}
            onClose={()=> {setVisible(false); setEditBook(null) }}
        />
        <AlertModal 
            visible={deleteitem}
            onClose={()=> setDeleteItem(false)}
        />
    </div>
  )
}

export {BookingReportTable}