import React, { useState, useEffect} from 'react'
import { Button, Card, Col, Dropdown, Flex, Image, Row, Space, Table} from 'antd'
import { ModuleTopHeading } from '../PageComponents'
import { useDispatch, useSelector } from 'react-redux'
import { actionsApi, TableLoader } from '../../shared'
import { propertyFor } from '../../shared/lookups'
import { MyInput, MyRadioGroup } from '../Forms'
import { useTranslation } from 'react-i18next'
import { propertyColumn } from '../../data/columns'
import { DownOutlined } from '@ant-design/icons'
import { NavLink } from 'react-router-dom'


const suffix = (<Image src='/assets/icons/search.png' width={'18px'} preview={false} />)
let timer

const PropertyOverView = () => {

    const {t}= useTranslation()
    const dispatch= useDispatch()
    const {data, loading, pagination}= useSelector(state => state?.property)
    const [propertyAddedFor, setPropertyAddedFor]= useState("Client")
    const [name , setName]= useState('')
    const [order, setOrder] = useState(1)
    const user= localStorage.getItem("user")
    const {role}= user ? JSON.parse(user) : {role: null}
    const isHost= role === "host" ? true : false

    useEffect(()=>{
        if(isHost && user){
            let {clientID}= JSON.parse(user)
            dispatch(actionsApi?.getAllProperties({name:'', propertyAddedFor: 2, ...pagination, pageNo: 1, clientID}))
        }
        else
            dispatch(actionsApi?.getAllProperties({name:'', propertyAddedFor: 2, ...pagination, pageNo: 1}))
    }, [isHost, user])

    const call=(pageNo=1, pageSize=20)=>{
        let {clientID}= JSON.parse(user)
        dispatch(actionsApi?.getAllProperties({name, propertyAddedFor: propertyFor?.find(fin => fin?.name === propertyAddedFor)?.id , pageNo, pageSize, clientID: isHost ? clientID : null}))
    }
  
    function searchHandler(searchKeyword, propertyAddedFor) {
        let {clientID}= JSON.parse(user)
        dispatch(actionsApi?.getAllProperties(
            {
                name: searchKeyword, 
                propertyAddedFor: propertyFor?.find(fin => fin?.name === propertyAddedFor)?.id, 
                pageNo:1, 
                pageSize: 10,
                clientID: isHost ? clientID : null
            }
        ))
    }
    function debounce(func, delay) {
        return function(...args) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(this, args)
            }, delay)
        };
    }
    const debouncedSearchHandler = debounce(searchHandler, 400)
    const columns = [
        ...propertyColumn
    ]

    return (
        <div>
            
            <Card className='shadow-c radius-12 border0'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' wrap align='center' gap={10}>
                            <Space>
                                <ModuleTopHeading name={t('Property overview')}/>
                                <small>
                                    <NavLink to='/property'>{t("View all")}</NavLink>
                                </small>
                            </Space>
                            <Flex justify='end' gap={10}>
                                <MyInput
                                    withoutForm
                                    prefix={suffix}
                                    placeholder={t('Search property ...')}
                                    value={name || ''}
                                    onChange={e => {debouncedSearchHandler(e.target.value, propertyAddedFor); setName(e.target.value)}}
                                    className='input-width'
                                    size='large'
                                />
                                <Dropdown
                                    menu={{ 
                                        items: [
                                            { label: <a href="#">A-Z</a>, key: '0' },
                                            { label: <a href="#">Z-A</a>, key: '1' }
                                        ], 
                                        onClick:  ({ key }) => {
                                            setOrder(key)

                                        }
                                    }}
                                    trigger={['click']}
                                    className='margin-top'
                                >
                                    <Button type='primary' size='large' icon={<DownOutlined />}>
                                        {t("Filter")}
                                    </Button>        
                                </Dropdown>   
                            </Flex>
                        </Flex>                        
                    </Col>
                    {
                        role!=='host' &&
                        <Col span={24}>
                            <Flex>
                                <MyRadioGroup
                                    options={propertyFor}
                                    defaultValue={'Client'}
                                    size='large'
                                    onChange={e => {searchHandler(name,  e?.target?.value); setPropertyAddedFor(e.target.value)}}
                                />
                            </Flex>
                        </Col>
                    }
                    <Col span={24}>
                        <Table
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: pagination?.totalRecords,
                                pageSize: pagination?.pageSize,
                                defaultPageSize: pagination?.pageSize,
                                current: pagination?.pageNo,
                                size: "default",
                                pageSizeOptions: ['10', '20', '50', '100'],
                                onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='bg-secondary-color border0'>Total: {total}</Button>,
                            }}
                            loading={
                                {
                                    ...TableLoader,
                                    spinning: loading
                                }
                            }
                        />
                    </Col>
                </Row>
            </Card>
        </div>
        
    )
}

export { PropertyOverView }
