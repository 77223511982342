import React, { useState, useEffect} from 'react'
import { Button, Table, Card, Col, Flex, Row, Space, Typography, Form} from 'antd'
import { ActionButton, AlertModal, ModuleTopHeading, MyRadioGroup, MyRentDate, MySelect } from '../../components'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { actionsApi, onlyMonth, onlyYear, TableLoader } from '../../shared'
import { apiCalls } from '../../shared/Apis'
import { expenseColumns } from '../../data/columns'
import { AddEditExpenseDrawer } from '../../components/ExpenseComponents/AddEditExpenseDrawer'
import { propertyFor } from '../../shared/lookups'
import { useTranslation } from 'react-i18next'

const {Text}= Typography
 
const Expense = () => {

    const {t}= useTranslation()
    const dispatch= useDispatch()
    const [form] = Form.useForm()
    const {data, loading, pagination}= useSelector(state => state?.expense)
    const {clientLookup}= useSelector(state => state?.client)
    const {propertyByClientLookup}= useSelector(state => state?.property)
    const {language}= useSelector(state => state?.app)
    const [ visible, setVisible ] = useState(false)
    const [ editExpense, setEditExpense ] = useState(null)
    const [ deleteItem, setDeleteItem ] = useState(false)
    const [propertyAddedFor, setPropertyAddedFor]= useState("Client")
    const user= localStorage.getItem("user")
    const {role}= user ? JSON.parse(user) : {role: null}


    useEffect(()=>{
        dispatch(actionsApi?.getPropertyLookup())
        if(!clientLookup?.length)
            dispatch(actionsApi?.getClientLookup())
        return (()=>{
            form.resetFields()
        })
    }, [])

    useEffect(()=>{
        if(user && role==='host' && clientLookup?.length){
            let {clientID}= JSON.parse(user)
            let clientName= clientLookup?.find(fin => fin?.id === clientID)?.name
            form.setFieldValue("clientID", clientName || null)
            getPropertyByClient(clientID, 2)
            form.setFieldValue("propertyID", null)
        }
    }, [user, role, clientLookup])
  
    function searchHandler() {
        dispatch(actionsApi?.getAllExpenses({
            payload: getPayload(),
            pageNo:1, 
            pageSize: 20
        }))
    }
    const searchExpense= ()=>{
        dispatch(actionsApi?.getAllExpenses({
            payload: getPayload(), 
            ...pagination, 
            pageNo: 1
        }))
    }
    const call=(pageNo=1, pageSize=20)=>{
        dispatch(actionsApi?.getAllExpenses({
            payload: getPayload(propertyAddedFor),
            pageNo,
            pageSize
        }))
    }

    const getPayload= ()=>{
        let data= form.getFieldsValue()
        return ({
            propertyID: data?.propertyID ? propertyByClientLookup?.find(fin => fin?.name === data?.propertyID)?.id : null,
            year: onlyYear(data?.year),
            month: onlyMonth(data?.month),
        })
    }
   
    const columns = [
        ...expenseColumns,
        role!=='host'?
        { 
            title: <Text strong>{t("Action")}</Text>,
            key: 'action',
            fixed: 'right',
            width: 200,
            align: language === 'ar' ? 'right' : 'left',
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title={t('edit expense')}
                        shape="circle"
                        icon={<EditOutlined/>}
                        onClick={() => { setVisible(true) ; setEditExpense(row)}}
                    />
                    <ActionButton
                        title={t('delete expense')}
                        icon={<DeleteOutlined/>}
                        onClick={() => {  setDeleteItem({id: row?.id, name: row?.name}) }}
                        danger
                    />
                </Space>
            )
        }:{}
    ]

    const getPropertyByClient= (clientID, propertyAddedFor)=>{
        dispatch(actionsApi?.getPropertyByClientLookup({clientID, propertyAddedFor}))
    }
    const [deletionLoading, setDeletionLoading]= useState(false)
    const deleteExpense= async ()=>{
        setDeletionLoading(true)
        let result= await apiCalls?.expense?.deleteExpense(deleteItem?.id)
        if(result){
            setDeleteItem(null)
            call()
        }
        setDeletionLoading(false)

    }


    return (
        <div>
            
            <Card className='shadow-c radius-12 border0'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        {
                            role!=='host' ?
                            <ModuleTopHeading name={t('Expense')} onClick={()=>setVisible(true)} />:
                            <ModuleTopHeading name={t('Expense')}/>     
                        }      
                    </Col>
                    <Col span={24}>
                        <Space size={12} wrap className='mbl-space w-100'>
                            {
                                role!=='host' &&
                                <MyRadioGroup
                                    options={propertyFor}
                                    defaultValue={'Client'}
                                    size='large'
                                    onChange={e => {
                                        if(e?.target?.value==="Najla"){
                                            getPropertyByClient(null, 1)
                                            form.setFieldValue("clientID", null)
                                        }
                                        else
                                            dispatch(actionsApi?.setPropertyByClientLookup([]))
                                        setPropertyAddedFor(e.target.value)
                                        form.setFieldValue("propertyID", null)
                                    }}
                                    className="trans-down"
                                />
                            }
                            <Form 
                                form={form}
                                layout="vertical"
                            >
                                <Flex justify='flex-end' align='center' className='mbl-vertical w-100' wrap gap={12}>
                                    {
                                        propertyAddedFor==="Client" &&
                                        <MySelect
                                            name='clientID'
                                            label={t('Client')}
                                            size='large' 
                                            placeholder={t('select...')}
                                            options={clientLookup}
                                            onChange= {(value)=>{
                                                value= clientLookup?.find(fin => fin?.name === value).id
                                                getPropertyByClient(value, 2)
                                                form.setFieldValue("propertyID", null)
                                            }} 
                                            className='width-200'
                                            disabled={role==='host'}
                                            showSearch
                                        />
                                    }
                                    <MySelect
                                        name='propertyID'
                                        label={t('Property')}
                                        size='large' 
                                        placeholder={t('select...')}
                                        options={propertyByClientLookup}
                                        className='width-200'
                                        required
                                        message= 'please select property'
                                        showSearch
                                    />
                                    <MyRentDate
                                        datePicker
                                        label={t("Year")}
                                        name='year'
                                        size='large'
                                        picker="year"
                                        placeholder={t('select year')}
                                        className='width-200'
                                    />
                                    <MyRentDate
                                        datePicker
                                        label={t("Month")}
                                        name='month'
                                        size='large'
                                        picker="month"
                                        placeholder={t('select month')}
                                        className='width-200'
                                    />
                                    <Button 
                                        type='primary' 
                                        size='large'
                                        onClick={searchExpense}
                                        className='trans-down'
                                    >
                                        {t("Search")}
                                    </Button>
                                </Flex>
                            </Form>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Table
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: pagination?.totalRecords,
                                pageSize: pagination?.pageSize,
                                defaultPageSize: pagination?.pageSize,
                                current: pagination?.pageNo,
                                size: "default",
                                pageSizeOptions: ['10', '20', '50', '100'],
                                onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='bg-secondary-color border0'>Total: {total}</Button>,
                            }}
                            loading={
                                {
                                    ...TableLoader,
                                    spinning: loading
                                }
                            }
                        />
                    </Col>
                </Row>
            </Card>
            <AddEditExpenseDrawer
                visible={visible}
                editExpense={editExpense}
                callBack={()=> { searchHandler()}}
                onClose={()=> {setVisible(false);setEditExpense(null)}}
            />
            <AlertModal 
                visible={deleteItem}
                callBack= {deleteExpense}
                deletionLoading={deletionLoading}
                onClose={()=>setDeleteItem(null)}
                booking
            />
        </div>
    )
}

export { Expense }
