import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography, Divider} from "antd"
import { MySingleFileUpload, MyInput, MySelect } from "../../Forms"
import { propertyCity } from "../../../shared/lookups/lookups"
import { apiCalls } from "../../../shared/Apis"

const { Title } = Typography

const AddEditClientDrawer = ({visible, onClose, editclient, searchHandler}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && editclient) {
            form.setFieldsValue({
                name: editclient?.name,
                email: editclient?.email,
                phoneNo: editclient?.phoneNo,
                nationality: editclient?.nationality,
                city: editclient?.city,
                residentalAddress: editclient?.residentalAddress
            })
        } else {
            form.resetFields();
        }
    }, [visible, editclient])

    const addUpdateClient= async()=>{
        setLoading(true)
        let data= form.getFieldsValue()
        const formdata = new FormData()
        if(editclient?.id)
            formdata.append("id", editclient?.id)
        formdata.append("name", data?.name)
        formdata.append("email", data?.email)
        formdata.append("password", data?.password)
        formdata.append("phoneNo", data?.phoneNo)
        formdata.append("nationality", data?.nationality)
        formdata.append("city", data?.city)
        formdata.append("residentalAddress", data?.residentalAddress)
        if(data?.ownerIdentityCard)
            formdata.append("ownerIdentityCard", data?.ownerIdentityCard)
        if(data?.contractCopy)
            formdata.append("contractCopy", data?.contractCopy)
        let result= await apiCalls?.client?.addUpdateClient(formdata)
        if(result){
            searchHandler('')
            closeDrawer()
        }
        setLoading(false)
    }

    const closeDrawer= ()=>{
        form.resetFields()
        onClose()
    }

  return (
    <Drawer
        title={editclient ? 'Edit Client' : 'Add Client'}
        onClose={closeDrawer}
        open={visible}
        width={700}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {editclient? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            onFinish={addUpdateClient}
            requiredMark={true}
        >
            <Row gutter={16}>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Client Name'
                        name='name'
                        placeholder='e.g John Doe'
                        size='large'
                        required
                        message='Please enter name'
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Email address'
                        name='email'
                        placeholder='e.g john@mymail.com'
                        size='large'
                        required
                        message='Please enter email'
                    />
                </Col>              
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Password'
                        name='password'
                        placeholder='e.g Naj@67SS'
                        size='large'
                        required
                        message='Please enter password'
                        type='password'
                    />
                </Col>    
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Phone Number'
                        type='number'
                        name='phoneNo'
                        placeholder='e.g +966 12345689'
                        size='large'
                        required
                        message='Please enter phone number'
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Nationality'
                        name="nationality" 
                        placeholder='e.g saudi'
                        size='large'
                        required
                        message='Please select nationality'
                        options={[
                            {
                                id: 1,
                                name: 'Saudi'
                            },
                            {
                                id: 2,
                                name: 'Non Saudi'
                            }
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'City'
                        name="city" 
                        placeholder='e.g Jeddah'
                        size='large'
                        required
                        message='Please select city'
                        options={propertyCity}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Residental address'
                        name='residentalAddress'
                        placeholder='e.g House 12, Blue Valley'
                        size='large'
                        required
                        message='Please enter residental address'
                    />
                </Col>
            </Row>
            <Divider>
                <Title level={5} className="my-0">DOCUMENTS</Title>
            </Divider>
            <Row gutter={16}>
                <Col span={24}>
                    <MySingleFileUpload
                        name='ownerIdentityCard'
                        label='Owner identity card'
                        required={!editclient}
                        message='please upload owner identity card'
                        form={form}
                        application={editclient?.ownerIdentityCard || null}
                    />
                </Col>
                <Col  span={24}>
                    <MySingleFileUpload
                        name='contractCopy'
                        label='Contract copy'
                        required={!editclient}
                        message='please upload contract copy'
                        form={form}
                        application={editclient?.contractCopy || null}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditClientDrawer}