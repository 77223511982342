import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getAddVendorDetail = createAsyncThunk('getVendorStatistics',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingAddvendorDetail())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'Post',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({title: object?.title, name: object?.name, email: object?.email, order: object?.order})
        };
        fetch(domainUrl + `/viewAllVendor`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(addvendorDetailResponse(result?.applications))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(addvendorDetailClear())
                message.error(error) 
            })
    }
)

const AddVendorSlice = createSlice({
    name: 'mastervendor',
    initialState: {
        data: null,
        loading: false,
        addvendorDetail: null,
        loadingAddVendorDetail: false,
        pagination:{
            pageNo:1,
            pageSize: 10,
            totalRecords:0,
        },
        filters: {
            title: '',
            order: 1,
        },
    },
    reducers: {
        gettingAddvendorDetail: state => {
            state.loadingAddVendorDetail=true
            state.addvendorDetail=null
        },
        addvendorDetailResponse: (state, action)=>{
            state.addvendorDetail= action.payload
            state.loadingAddVendorDetail= false
        },
        addvendorDetailClear: (state)=>{
            state.addvendorDetail= null
            state.loadingAddVendorDetail= false
        },
        changeAddvendorDetailFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})
export const { 
    gettingAddvendorDetail, addvendorDetailResponse, addvendorDetailClear,
    changeAddvendorDetailFilters, setPages
} = AddVendorSlice.actions;
export default AddVendorSlice.reducer;