import { Flex, Typography } from "antd"
import { simpleDateFormat, simpleDateFormatUtcToLocal } from "../../shared"
import { ColumnName } from "../ColumnName"
const {Text}= Typography

let lang= localStorage.getItem("lang")
lang= lang || 'en'

export const bookingColumns= [
    {
        title: 
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Property Name"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'propertyName',
        fixed: 'left',
        align: lang === 'ar' ? 'right' : 'left'
    },
    {
        title: 
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Client Name"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'clientName',
        align: lang === 'ar' ? 'right' : 'left'
    },
    {
        title:
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Guest Name"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'name',
        align: lang === 'ar' ? 'right' : 'left'
    },
    {
        title: 
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Number of Nights"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'numberOFnights',
        align: lang === 'ar' ? 'right' : 'left'
    },
    {
        title: 
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Check-in date"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'checkINDate',
        align: lang === 'ar' ? 'right' : 'left',
        render: col => <Text>{simpleDateFormat(col)}</Text>
    },
    {
        title: 
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Check-out date"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'checkOutDate',
        align: lang === 'ar' ? 'right' : 'left',
        render: col => <Text>{simpleDateFormat(col)}</Text>
    },
    {
        title:
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Added By"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'addedBy',
        align: lang === 'ar' ? 'right' : 'left',
        render: col => <Text>{col?.name}</Text>
    },
    {
        title: 
            <Flex align='center' gap={4} justify={lang === "ar" ? "end" : "start"}>
                <ColumnName name="Time Stamp"/>
                <img src="/assets/icons/tb-ar.png" width={18} alt="" />
            </Flex>,
        dataIndex: 'timeStamp',
        align: lang === 'ar' ? 'right' : 'left',
        render: (_, row) => <Text>{simpleDateFormatUtcToLocal(row?.updated_at || row?.created_at)}</Text>
    }
]