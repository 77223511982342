import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography} from "antd"
import { MyInput, MyRentDate, MySelect, MySingleFileUpload } from "../../Forms"
import { propertyCity, propertyFor, propertyOwnership, propertytype, rentPeriod } from "../../../shared/lookups/lookups"
import { apiCalls } from "../../../shared/Apis"
import { useSelector } from "react-redux"
import dayjs from "dayjs"
const { Title } = Typography

const AddEditPropertyDrawer = ({visible, onClose, editProperty, searchHandler}) => {
  
    const [form] = Form.useForm()
    const {clientLookup}= useSelector(state => state?.client)
    const [ loading, setLoading ] = useState(false)  
    const [ typeShow, setTypeShow ] = useState(false)
    const [ owner, setOwner] = useState(false)

    useEffect(() => {
        if (visible && editProperty) {
            form.setFieldsValue({
                ...editProperty,
                propertyAddedFor: propertyFor?.find(fil=> fil?.id == editProperty?.propertyAddedFor)?.name,
                ownerShip: propertyOwnership?.find(fil=> fil?.id == editProperty?.ownerShip)?.name,
                clientID: editProperty?.propertyAddedFor == 2 ? clientLookup?.find(fin => fin?.id === editProperty?.clientID)?.name : "",
                rentPeriod: editProperty?.propertyAddedFor == 2 ? rentPeriod?.find(fin => fin?.id === parseInt(editProperty?.rentPeriod))?.name : "",
                contractDate: editProperty?.contractDate ? dayjs(editProperty?.contractDate) : ""
            })
            if(editProperty?.propertyAddedFor == 2)
                setTypeShow(true)
            if(editProperty?.ownerShip == 2)
                setOwner(true)
        }
        else if(visible){
            setTypeShow(false)
            setOwner(false)
        }
        else 
            form.resetFields()
    }, [visible, editProperty])

    const addEditProperty= async ()=>{
        
        setLoading(true)
        let data= form.getFieldsValue()
        const formdata = new FormData()
        let propertyAddedFor= propertyFor?.find(fil=> fil?.name === data?.propertyAddedFor)?.id
        if(editProperty?.id)
            formdata.append("id", editProperty?.id)
        formdata.append("propertyAddedFor", propertyAddedFor)
        if(propertyAddedFor===2)
            formdata.append("clientID", clientLookup?.find(fin=> fin?.name===data?.clientID)?.id)
        formdata.append("propertyName",  data?.propertyName)
        formdata.append("city",  data?.city)
        formdata.append("address", data?.address)
        formdata.append("neighborhood", data?.neighborhood)
        formdata.append("propertyType", data?.propertyType)
        formdata.append("ownerShip", propertyOwnership?.find(fil=> fil?.name === data?.ownerShip)?.id)
        formdata.append("bedrooms", data?.bedrooms)
        formdata.append("bathrooms", data?.bathrooms)
        formdata.append("furnished", data?.furnished)
        formdata.append("ownerName", data?.ownerName || '')
        formdata.append("ownerEmail", data?.ownerEmail || '')
        formdata.append("ownerContactNumber", data?.ownerContactNumber || null)
        if(data?.ownerContract)
            formdata.append("ownerContract", data?.ownerContract)
        formdata.append("addtionalInfo", data?.addtionalInfo || "")
        formdata.append("rentAmount", data?.rentAmount || 0)
        formdata.append("rentPeriod", rentPeriod?.find(fil=> fil?.name === data?.rentPeriod)?.id)
        formdata.append("contractDate", dayjs(data?.contractDate).format("YYYY-MM-DD"))
        formdata.append("addtionalRentInfo", data?.addtionalRentInfo || "")
        let result= await apiCalls?.property?.addUpdateProperty(formdata)
        if(result){
            searchHandler("")
            closeDrawer()
        }
        setLoading(false)
    }
    const closeDrawer= ()=>{
        form.resetFields()
        onClose()
    }


    return (
        <Drawer
            title={editProperty?'Edit Property':'Add New Property'}
            onClose={closeDrawer}
            open={visible}
            width={930}
            footer={
                <Space className="w-100 right">
                    <Button 
                        onClick={closeDrawer}
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        className='btnsave'
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        onClick={()=> form.submit()}
                    >
                        {editProperty? 'Update Property' : 'Save Property'}
                    </Button>
                </Space>
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                onFinish={addEditProperty}
            >
                <Row gutter={16}>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MySelect
                            label= 'Property adding for'
                            name="propertyAddedFor" 
                            required
                            message='Please select'
                            size='large'
                            options={propertyFor}
                            onChange={ e => setTypeShow(e === 'Client' ? true  : false )}
                        />
                    </Col>
                    {
                        typeShow ?
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <MySelect
                                label= 'Client Name'
                                name="clientID" 
                                required
                                size='large'
                                message='Please select client'
                                placeholder=''
                                options={clientLookup}
                            />
                        </Col>
                        :
                        <></>
                    }
                    <Col span={24}>
                        <Title level={4} className="mt-0 mb-3 fw-500">PROPERTY DETAILS:</Title>
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            label='Property name'
                            name='propertyName'
                            placeholder='e.g Jeddah Flats'
                            required
                            size='large'
                            message='Please enter property name'
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MySelect
                            label= 'Property city'
                            name="city" 
                            size='large'
                            required
                            message='Please choose city name'
                            options={propertyCity}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            label='Property address'
                            name='address'
                            size='large'
                            placeholder='e.g floor #12'
                            required
                            message='Please enter property address'
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            label='Property Neighbourhood'
                            name='neighborhood'
                            size='large'
                            placeholder='e.g Near Makkah Tower'
                            required
                            message='Please enter property neighbourhood'
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MySelect
                            label= 'Property type'
                            name="propertyType" 
                            size='large'
                            required
                            message='Please choose property type'
                            options={propertytype}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MySelect
                            label= 'Ownership'
                            name="ownerShip"
                            size='large'
                            required
                            message='Please choose ownership' 
                            options={propertyOwnership}
                            onChange={e => setOwner( e === 'Rented' ? true : false)}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            label='Total bedroom'
                            name='bedrooms'
                            size='large'
                            placeholder='e.g 3'
                            required
                            message='Please enter a number'
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            label='Total Bathroom'
                            name='bathrooms'
                            size='large'
                            placeholder='e.g 3'
                            required
                            message='Please enter number of bathroom'
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MySelect
                            label= 'Furnished'
                            name="furnished" 
                            size='large'
                            required
                            message='Please select'
                            options={[
                                {key: 1, name: 'Yes'},
                                {key: 2, name: 'No'}
                            ]}
                        />
                    </Col>
                    {/* owner detail inputs */}
                    {
                        owner?
                        <>
                            <Col span={24}>
                                <Title level={4} className="mt-0 mb-3 fw-500">OWNER DETAILS:</Title>
                            </Col>
                            <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                <MyInput
                                    label='Owner name'
                                    name='ownerName'
                                    placeholder='e.g John Doe'
                                    size='large'
                                    required
                                    message='Please enter owner name'
                                />
                            </Col>
                            <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                <MyInput
                                    label='Owner Email Address'
                                    name='ownerEmail'
                                    placeholder='e.g John@xyz.com'
                                    size='large'
                                    required
                                    message='Please enter email address'
                                />
                            </Col>
                            <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                <MyInput
                                    label='Owner Contact Number'
                                    name='ownerContactNumber'
                                    placeholder='e.g 3445592382'
                                    size='large'
                                    required
                                    message='Please enter owner contact number'
                                />
                            </Col>
                            <Col span={24}>
                                <MyInput
                                    textArea
                                    label='Additional owner information'
                                    name='addtionalInfo'
                                    rows={5}
                                />
                            </Col>
                            <Col span={24}>
                                <MySingleFileUpload
                                    name='ownerContract'
                                    label='Owner contract (pdf)'
                                    required
                                    message='please upload owner contract'
                                    form={form}
                                    application={editProperty?.ownerContract || null}
                                />
                            </Col>
                            <Col span={24}>
                                <Title level={4} className="mt-0 mb-3 fw-500">RENT DETAILS:</Title>
                            </Col>
                            <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                <MyInput
                                    label='Rent amount'
                                    name='rentAmount'
                                    placeholder='e.g 1000'
                                    size='large'
                                    required
                                    message='Please enter rent amount'
                                />
                            </Col>
                            <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                <MySelect
                                    label= 'Rent period'
                                    name="rentPeriod" 
                                    size='large'
                                    required
                                    message='Please select'
                                    options={rentPeriod}
                                />
                            </Col>
                            <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                <MyRentDate
                                    datePicker
                                    label="Contract date"
                                    name='contractDate'
                                    size='large'
                                    required
                                    message="Please select contract date"
                                />
                            </Col>
                            <Col span={24}>
                                <MyInput
                                    textArea
                                    label='Additional rent information'
                                    name='addtionalRentInfo'
                                    rows={5}
                                />
                            </Col>
                        </>
                        :
                        <></>
                    }
                    
                </Row>
            </Form>
        </Drawer>
    )
}

export {AddEditPropertyDrawer}