import { Row, Col, Table} from 'antd'
import { ModuleTopHeading} from '../../PageComponents'
import { rentColumn } from '../../../data/columns'
import { TableLoader } from '../../../shared'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const RentReportTable = () => {

    const {t}= useTranslation()
    const {rent, loadingRent}= useSelector(state => state?.report)
    const columns = [
        ...rentColumn
    ]
   

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <ModuleTopHeading 
                    name={t('Rent')}  
                />
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={rent} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    pagination={false}
                    loading={
                        {
                            ...TableLoader,
                            spinning: loadingRent
                        }
                    }
                />
            </Col>
        </Row>
    </div>
  )
}

export {RentReportTable}