import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Dropdown, Flex, Table, Image, Row, Space, Typography } from 'antd'
import { ActionButton, AlertModal, ModuleTopHeading, MyInput, MyRadioGroup, UploadImageModal, ViewProperty } from '../../components'
import { AddEditPropertyDrawer } from '../../components/PropertyDash/AddEditPerpertyDrawer'
import { DeleteOutlined, DownOutlined, EditOutlined, EyeOutlined, FileExcelOutlined, FileImageOutlined,  } from '@ant-design/icons'
import { actionsApi } from '../../shared/redux/actionsApi'
import { useDispatch, useSelector} from 'react-redux'
import { apiCalls } from '../../shared/Apis'
import { TableLoader } from '../../shared'
import { useNavigate } from 'react-router-dom'
import { propertyFor } from '../../shared/lookups'
import { useTranslation } from 'react-i18next'
import { propertyColumn } from '../../data/columns'

const {Text}= Typography
const suffix = (<Image src='/assets/icons/search.png' width={'18px'} preview={false} />)
let timer

const PropertyDash = () => {

    const {t}= useTranslation()
    const dispatch= useDispatch()
    const navigate= useNavigate()
    const {data, loading, pagination}= useSelector(state => state?.property)
    const {language}= useSelector(state => state?.app)
    const [ visible, setVisible ] = useState(false)
    const [visiblePropertyModal, setVisiblePropertyModal] = useState(false);
    const [ editproperty, setEditProperty ] = useState(null)
    const [ deleteItem, setDeleteItem ] = useState(false)
    const [ property, setProperty ] = useState(false)
    const [propertyAddedFor, setPropertyAddedFor]= useState("Client")
    const [name , setName]= useState('')
    const [order, setOrder] = useState(1)
    const user= localStorage.getItem("user")
    const {role}= user ? JSON.parse(user) : {role: null}
    const isHost= role === "host" ? true : false


    useEffect(()=>{
        dispatch(actionsApi?.getClientLookup())
        if(isHost && user){
            let {clientID}= JSON.parse(user)
            dispatch(actionsApi?.getAllProperties({name:'', propertyAddedFor: 2, ...pagination, pageNo: 1, clientID}))
        }
        else
            dispatch(actionsApi?.getAllProperties({name:'', propertyAddedFor: 2, ...pagination, pageNo: 1}))
    }, [isHost, user])

    const call=(pageNo=1, pageSize=20)=>{
        let {clientID}= JSON.parse(user)
        dispatch(actionsApi?.getAllProperties({name, propertyAddedFor: propertyFor?.find(fin => fin?.name === propertyAddedFor)?.id , pageNo, pageSize, clientID: isHost ? clientID : null}))
    }
  
    function searchHandler(searchKeyword, propertyAddedFor) {
        let {clientID}= JSON.parse(user)
        dispatch(actionsApi?.getAllProperties(
            {
                name: searchKeyword, 
                propertyAddedFor: propertyFor?.find(fin => fin?.name === propertyAddedFor)?.id, 
                pageNo:1, 
                pageSize: 10,
                clientID: isHost ? clientID : null
            }
        ))
    }
    function debounce(func, delay) {
        return function(...args) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(this, args)
            }, delay)
        };
    }
    const debouncedSearchHandler = debounce(searchHandler, 400)
    const columns = [
        ...propertyColumn,
        {
            title: <Text strong>{t("Action")}</Text>,
            key: 'action',
            fixed: 'right',
            width: 180,
            align: language === 'ar' ? 'right' : 'left',
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title={t('View property')}
                        icon={<EyeOutlined/>}
                        onClick={() => setProperty(row)}
                        type='primary'
                        style={{background: "#F7B31C"}}
                    />
                    {
                        role!=='host' &&
                        <ActionButton
                            title={t('Edit property')}
                            icon={<EditOutlined/>}
                            onClick={() => { setVisible(true); setEditProperty(row)}}
                        />
                    }
                    <ActionButton
                        title={t('Add property images')}
                        icon={<FileImageOutlined/>}
                        onClick={() => setVisiblePropertyModal({propertyID: row?.id})}
                        style={{background: "#B4BCF7"}}
                        type='primary'
                    />
                    <ActionButton
                        title={t('Report')}
                        icon={<FileExcelOutlined/>}
                        onClick={() =>{
                            if(row?.clientID)
                                navigate(`/report/${row?.id}/client/${row?.clientID}`)
                            else
                                navigate(`/report/${row?.id}`)
                        }}
                        type='primary'
                        style={{background: "#015F81"}}
                    />
                    {
                        role!=='host' &&
                        <ActionButton
                            title={t('Delete property')}
                            icon={<DeleteOutlined/>}
                            onClick={() => { setDeleteItem({id: row?.id, name: row?.propertyName}) }}
                            danger
                        />
                    }
                </Space>
            ),
        },
    ]
    const [deletionLoading, setDeletionLoading]= useState(false)
    const deleteProperty= async ()=>{
        setDeletionLoading(true)
        let result= await apiCalls?.property?.deleteProperty(deleteItem?.id)
        if(result){
            setDeleteItem(null)
            searchHandler(name, propertyAddedFor) 
        }
        setDeletionLoading(false)
    }
    return (
        <div>
            <Card className='radius-12 border0 shadow-c'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' wrap gap={10}>
                            {
                                role!=='host'? 
                                <ModuleTopHeading name={t('Property')} onClick={()=>setVisible(true)} /> :
                                <ModuleTopHeading name={t('Property')}/>
                            }
                            <Flex justify='end' gap={10}>
                                <MyInput
                                    withoutForm
                                    prefix={suffix}
                                    placeholder={t('Search property ...')}
                                    value={name || ''}
                                    onChange={e => {debouncedSearchHandler(e.target.value, propertyAddedFor); setName(e.target.value)}}
                                    className='input-width'
                                />
                                <Dropdown
                                    menu={{ 
                                        items: [
                                            { label: <a href="#">A-Z</a>, key: '0' },
                                            { label: <a href="#">Z-A</a>, key: '1' }
                                        ], 
                                        onClick:  ({ key }) => {
                                            setOrder(key)

                                        }
                                    }}
                                    trigger={['click']}
                                    className='margin-top'
                                >
                                    <Button type='primary' size='large' icon={<DownOutlined />}>
                                        {t("Filter")}
                                    </Button>        
                                </Dropdown>   
                            </Flex>
                        </Flex>                        
                    </Col>
                    {
                        role!=='host' &&
                        <Col span={24}>
                            <Flex>
                                <MyRadioGroup
                                    options={propertyFor}
                                    defaultValue={'Client'}
                                    size='large'
                                    onChange={e => {searchHandler(name,  e?.target?.value); setPropertyAddedFor(e.target.value)}}
                                />
                            </Flex>
                        </Col>
                    }
                    <Col span={24}>
                        <Table
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: pagination?.totalRecords,
                                pageSize: pagination?.pageSize,
                                defaultPageSize: pagination?.pageSize,
                                current: pagination?.pageNo,
                                size: "default",
                                pageSizeOptions: ['10', '20', '50', '100'],
                                onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='bg-secondary-color border0'>Total: {total}</Button>,
                            }}
                            loading={
                                {
                                    ...TableLoader,
                                    spinning: loading
                                }
                            }
                        />
                    </Col>
                </Row>
            </Card>
            <ViewProperty
                property={property}
                onClose={()=> setProperty(null)}
            />
            <AddEditPropertyDrawer
                visible={visible}
                editProperty={editproperty}
                onClose={()=> {setVisible(false); setEditProperty(null)}}
                searchHandler={()=>call(1, 20)}
            />
            <UploadImageModal
                visible={visiblePropertyModal}
                onClose={()=> {setVisiblePropertyModal(null)}}
            />
            <AlertModal 
                visible={deleteItem}
                callBack= {deleteProperty}
                deletionLoading={deletionLoading}
                onClose={()=>setDeleteItem(null)}
            />
        </div>
    )
}

export { PropertyDash }
