import React, { useEffect } from 'react'
import { Card, Col, Flex, Row, Table, Typography} from 'antd'
import { ModuleTopHeading } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { actionsApi } from '../../shared/redux/actionsApi'
import { TableLoader} from '../../shared'
import { useTranslation } from 'react-i18next'
const { Text } = Typography

const Financials = () => {

    const {t}= useTranslation()
    const dispatch= useDispatch()
    const {data, loading}= useSelector(state => state?.financials)
    const {language}= useSelector(state => state?.app)

    
    useEffect(()=>{
        dispatch(actionsApi?.getFinancials())
    }, [])
    const columns = [
        {
            title:
                <Flex align='center' gap={4} justify={language === "ar" ? "end" : "start"}>
                    <Text strong>
                        {t("Total Properties")}
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'totalProperties',
            align: language === 'ar' ? 'right' : 'left'
        },
        {
            title:
                <Flex align='center' gap={4} justify={language === "ar" ? "end" : "start"}>
                    <Text strong>
                        {t("Expenses")}
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'expenses',
            align: language === 'ar' ? 'right' : 'left'
        },
        {
            title:
                <Flex align='center' gap={4} justify={language === "ar" ? "end" : "start"}>
                    <Text strong>
                        {t("Booking")}
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'totalBooking',
            align: language === 'ar' ? 'right' : 'left'
        },
        {
            title:
                <Flex align='center' gap={4} justify={language === "ar" ? "end" : "start"}>
                    <Text strong>
                        {t("Bank Transfer")}
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'moneyTransferFromUS',
            align: language === 'ar' ? 'right' : 'left'
        },
        {
            title:
                <Flex align='center' gap={4} justify={language === "ar" ? "end" : "start"}>
                    <Text strong>
                        {t("Rent(Monthly)")}
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'totalRent',
            align: language === 'ar' ? 'right' : 'left'
        },
    ]
    return (
        <div>
            
            <Card className='radius-12 border0 shadow-c'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <ModuleTopHeading name={t('Financials')}/>                       
                    </Col>
                    <Col span={24}>
                        <Table
                            size='large'
                            columns={columns} 
                            dataSource={data ? [data] : []} 
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={false}
                            loading={
                                {
                                    ...TableLoader,
                                    spinning: loading
                                }
                            }
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export { Financials }
