import { message } from "antd";
import { checkAuthorization } from "../../helpers";
import { domainUrl } from "../../constants";

const changePassword = (object) => {

    const {userToken} = checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    myHeaders.append('content-type', 'application/json')

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(object),
        redirect: 'follow'
    };
    let url = '/changePassword'
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error || 'Something went wrong');
            return 0
        })
    )
}

const user = {
    changePassword
}

export {user}