import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstants/domainUrl'
import { checkAuthorization, isUnAuthorize } from '../../../helpers'

export const authCheck = createAsyncThunk('LoginCheck',
    async (_, { dispatch, getState }) => {

        if(isUnAuthorize()){
            dispatch(loginCheckResponse(false))
            return
        }
        const { userToken }= checkAuthorization()
        dispatch(gettingLoginCheck())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/loginCheck`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    let user={...result?.user}
                    delete user?.token
                    delete user?.refreshToken
                    localStorage.setItem('user', JSON.stringify(user))
                    dispatch(loginCheckResponse(true))
                }
                else 
                    dispatch(loginCheckResponse(false))
            })
            .catch(_ => {
                dispatch(loginCheckResponse(false))
            })
    }
)

const LoginCheckSlice = createSlice({
    name: 'loginCheck',
    initialState: {
        isLogin: false,
        loading: true
    },
    reducers: {
        gettingLoginCheck: (state, action) => {
            state.loading = true
        },
        loginCheckResponse: (state, action) => {
            state.isLogin = action.payload
            state.loading = false
        }
    },
})

export const { gettingLoginCheck, loginCheckResponse } = LoginCheckSlice.actions
export default LoginCheckSlice.reducer