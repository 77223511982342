import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { message } from 'antd'
import { checkAuthorization } from '../../../helpers'

export const getAllBookings = createAsyncThunk('booking',
    async (object, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        dispatch(getting())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(object?.payload)
        }
        fetch(domainUrl + "/viewAllBookingsNajlaNew", requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.success){
                dispatch(bookingResponse(result?.data))
                dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
            }
            else 
                throw result?.message
        })
        .catch(error => { 
            message.error(error)
            dispatch(bookingClear()) 
        })
    }
)

const BookingSlice = createSlice({
    name: 'booking',
    initialState: {
        data: [],
        loading: false,
        pagination:{
            pageNo:1,
            pageSize: 20,
            totalRecords:0
        },
        filters: {
            propertyAddedFor: "2",
            clientID: null,
            propertyID: null,
            year: null,
            month: null
        }
    },
    reducers: {
        getting: state => {
            state.loading = true
        },
        bookingResponse: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        bookingClear: state => {
            state.data = []
            state.loading = false
        },
        changeBookingFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        }
    }
})

export const { getting, bookingResponse, bookingClear, changeBookingFilters, setPages} = BookingSlice.actions;
export default BookingSlice.reducer;