import React, { useState } from "react"
import { Badge, Avatar} from "antd"
import NotificationsDrawer from "./NotificationsDrawer"
import { BellOutlined } from "@ant-design/icons"

export const Notifications = () => {
    
    const [visible, setVisible]= useState(false)
    return (
        <>
            <Badge count={9} overflowCount={9}>
                <Avatar 
                    size={32} 
                    icon={<BellOutlined style={{fontSize: '19px'}}/>} 
                    style={{cursor: 'pointer'}}
                    onClick={()=> setVisible(true)}
                />
            </Badge>
            <NotificationsDrawer
                visible={visible}
                onClose={()=> setVisible(false)}
            />
        </>
    )
}