import {Modal, Space, Typography, Divider} from 'antd'
import "./index.css"
import { useState } from 'react';
const { Title, Text, Link} = Typography

const ViewClient = ({viewData,onClose}) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const openFile= (file)=>{
        let extension= file?.split('.').pop().split(/\#|\?/)[0]
        if(['pdf', 'txt', 'doc', 'docx']?.includes(extension)){
            let pdfWindow = window.open("")
            pdfWindow.document.write(`<object data="${file}" type="application/pdf" width="100%" height="100%">Your browser does not support embedded PDF files.</object>`)
        }
        else if(['jpg','jpeg','png']?.includes(extension)){
            setPreviewOpen(true)
            setPreviewImage(file)
        }
    }
  return (
    <div>
        <Modal
            title={ viewData?.name+"'s Detail"}
            centered
            width={700}
            visible={viewData}
            onOk={onClose}
            onCancel={onClose}
            footer={null}
            className='view-modal'
        >
            <div className='py-3'>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>Email:</Title>
                    <Text>
                        {
                            viewData?.email
                        }
                    </Text>
                </Space>
                <Divider/>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>Phone Number:</Title>
                    <Text>
                        {
                            viewData?.phoneNo
                        }
                    </Text>
                </Space>
                <Divider/>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>Nationality:</Title>
                    <Text>
                        {viewData?.nationality}
                    </Text>
                </Space>
                <Divider/>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>City:</Title>
                    <Text>
                        {
                            viewData?.city
                        }
                    </Text>
                </Space>
                <Divider/>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>Total Properties:</Title>
                    <Text>
                        {
                            viewData?.properties_count
                        }
                    </Text>
                </Space>
                <Divider/>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>Residental address:</Title>
                    <Text>
                        {
                            viewData?.residentalAddress
                        }
                    </Text>
                </Space>
                <Divider/>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>Owner identity card:</Title>
                    <Link onClick={()=> openFile(viewData?.ownerIdentityCard)}>
                        {
                            viewData?.ownerIdentityCardName
                        }
                    </Link>
                </Space>
                <Divider/>
                <Space style={{justifyContent: "space-between", width: "100%"}}>
                    <Title level={5} className='m-0 fw-500'>Contract copy:</Title>
                    <Link onClick={()=> openFile(viewData?.contractCopy)}>
                        {
                            viewData?.contractCopyName
                        }
                    </Link>
                </Space>
            </div>
        </Modal>
        <Modal 
            open={previewOpen} 
            title={'Preview'} 
            footer={null} 
            onCancel={()=>{setPreviewImage(null); setPreviewOpen(null)}}
        >
            <img alt="najla-rental" style={{ width: '100%' }} src={previewImage} />
        </Modal>
    </div>
  )
}
export {ViewClient}