import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Dropdown, Flex, Form, Image, Row, Space, Table, Typography } from 'antd'
import { ActionButton, AddEditEmpDrawer, AddHostDrawer, AlertModal, ModuleTopHeading, MyInput } from '../../components';
import { DownOutlined} from '@ant-design/icons';
import { actionsApi } from '../../shared/redux/actionsApi';
import { useDispatch, useSelector} from 'react-redux';
import { apiCalls } from '../../shared/Apis';
import { TableLoader } from '../../shared';
import moment from 'moment';


const {Text}= Typography
const suffix = (
    <Image src='/assets/icons/search.png' width={'18px'} preview={false} />
  )
  let timer
const Host = () => {

    const dispatch= useDispatch()
    const {data, loading, filters, pagination}= useSelector(state => state?.host)
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [name , setName]= useState('')
    const [order, setOrder] = useState(1)


    useEffect(()=>{
        dispatch(actionsApi?.getAllHost({...filters, ...pagination, pageNo: 1}))
        setName(filters?.name)
    }, [])

    const call=(pageNo=1, pageSize=8)=>{
        dispatch(actionsApi?.getAllHost({...filters, pageNo, pageSize}))
    }
  
    function searchHandler(searchKeyword) {
        dispatch(actionsApi?.getAllHost({name: searchKeyword, order, pageNo:1, pageSize: 10}))
        dispatch(actionsApi?.changeHostFilters({name: searchKeyword}))
    }
    function debounce(func, delay) {
        return function(...args) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(this, args)
            }, delay)
        };
    }
    const debouncedSearchHandler = debounce(searchHandler, 400)
    const columns = [
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Host Name
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        User Name
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'userName',
            key:'userName'
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Email
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'email',
            key:'email'
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Contact Number
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'phoneNo',
            key:'phoneNo'
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Gender
                    </Text>
                    <img src="/assets/icons/tb-ar.png" width={18} alt="" />
                </Flex>,
            dataIndex: 'gender',
            key:'gender'
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 180,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit item'
                        icon={<Image src='/assets/icons/edit.png' width={16} preview={false} />}
                        onClick={() => { setVisible(true); setEditItem(row)}}
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={16} preview={false} />}
                        onClick={() => { setDeleteItem({id: row?.id, name: row?.name}) }}
                    />
                </Space>
            ),
        },
    ]
    const [deletionLoading, setDeletionLoading]= useState(false)
    const deleteHost= async ()=>{
        setDeletionLoading(true)
        let result= await apiCalls?.hostapi?.deleteHost(deleteitem?.id)
        if(result){
            setDeleteItem(null)
            searchHandler('')
        }
        setDeletionLoading(false)
    }

    return (
        <div>
            <Card className='shadow-c radius-12 border0'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' wrap gap={10}>
                            <Space>
                                <ModuleTopHeading name='Host' onClick={()=>setVisible(true)} />
                            </Space>
                            <Flex justify='end' gap={10}>
                                <MyInput
                                    withoutForm
                                    prefix={suffix}
                                    placeholder='Search host ...'
                                    value={name || ''}
                                    onChange={e => {debouncedSearchHandler(e.target.value); setName(e.target.value)}}
                                />
                                <Dropdown
                                    menu={{ 
                                        items: [
                                            { label: <a href="#">A-Z</a>, key: '0' },
                                            { label: <a href="#">Z-A</a>, key: '1' }
                                        ], 
                                        onClick:  ({ key }) => {
                                            setOrder(key)

                                        }
                                    }}
                                    trigger={['click']}
                                    className='margin-top'
                                    >
                                    <Button className='btnsave pad-x border0 fs-14 fw-400' >
                                        Filter
                                        <DownOutlined />
                                    </Button>        
                                </Dropdown>   
                            </Flex>
                        </Flex>                        
                    </Col>
                    <Col span={24}>
                        <Table
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: pagination?.totalRecords,
                                pageSize: pagination?.pageSize,
                                defaultPageSize: pagination?.pageSize,
                                current: pagination?.pageNo,
                                size: "default",
                                pageSizeOptions: ['10', '20', '50', '100'],
                                onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='bg-secondary-color border0'>Total: {total}</Button>,
                            }}
                            loading={
                                {
                                    ...TableLoader,
                                    spinning: loading
                                }
                            }
                        />
                    </Col>
                </Row>
            </Card>
            <AddHostDrawer 
                visible={visible}
                edititem={edititem}
                onClose={()=> {setVisible(false);setEditItem(null)}}
                searchHandler={searchHandler}
            />
            <AlertModal 
                visible={deleteitem}
                callBack= {deleteHost}
                deletionLoading={deletionLoading}
                onClose={()=>setDeleteItem(null)}
            />
        </div>
    )
}

export { Host }
