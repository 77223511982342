import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography} from "antd"
import { MyInput, MyRentDate, MySelect, MySingleFileUpload } from "../../Forms"
import { useSelector } from "react-redux"
import { apiCalls } from "../../../shared/Apis"
import { useParams } from "react-router-dom"
import { onlyDate } from "../../../shared"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"

const { Title} = Typography

const AddEditExpenseDrawer = ({visible, onClose, editExpense, callBack}) => {
 
    const {t}= useTranslation()
    const {propertyID}= useParams()
    console.log(propertyID)
    const {propertyLookup}= useSelector(state => state?.property)
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && editExpense) {
            form.setFieldsValue({
                ...editExpense,
                propertyID: propertyLookup?.find(fin => fin?.id === editExpense?.propertyID)?.name,
                date: dayjs(editExpense?.date)
            })
        } else {
            form.resetFields();
        }
    }, [visible, editExpense])
    useEffect(()=>{
        if(propertyID && propertyLookup?.length)
            form.setFieldValue("propertyID", propertyLookup?.find(fin => fin?.id === propertyID)?.name)
    }, [propertyID, propertyLookup])

    const addUpdateExpense= async ()=>{
        setLoading(true)
        const data= form.getFieldsValue()
        const formdata = new FormData()
        if(editExpense?.id)
            formdata.append("id", editExpense?.id)
        formdata.append("propertyID", propertyLookup?.find(fin => fin?.name === data?.propertyID)?.id)
        formdata.append("name", data?.name)
        formdata.append("amount", data?.amount)
        formdata.append("date", onlyDate(data?.date))
        if(data?.bill)
            formdata.append("bill", data?.bill)
        let result= await apiCalls?.expense?.addUpdateExpense(formdata)
        if(result){
            closeDrawer()
            callBack()
        }
        setLoading(false)
    }
    const closeDrawer= ()=>{
        form.resetFields()
        onClose()
    }

  return (
    <Drawer
        title={editExpense ? 'Edit expense' : 'Add new expense'}
        onClose={closeDrawer}
        open={visible}
        width={800} 
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {editExpense ? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            onFinish={addUpdateExpense}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        // disabled={propertyID}
                        label= 'Property Name'
                        name="propertyID" 
                        placeholder='e.g denmark valley'
                        required
                        size='large'
                        message='Please select property'
                        options={propertyLookup}
                    />
                </Col>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">EXPENSE DETAILS</Title>
                </Col>
                <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MySingleFileUpload
                        name='bill'
                        label='Bill (pdf)'
                        form={form}
                        application={editExpense?.bill || null}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Expense Name'
                        name='name'
                        placeholder='e.g Fan repair'
                        size='large'
                        required
                        message='Please enter expense name'
                    />
                </Col> 
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Expense Amount'
                        type='number'
                        name='amount'
                        placeholder='e.g 10000'
                        size='large'
                        required
                        message='Please enter expense amount'
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyRentDate
                        datePicker
                        label={t("Expense date")}
                        name='date'
                        size='large'
                        placeholder={t('select date')}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditExpenseDrawer}
