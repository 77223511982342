import { Space, Image, Typography} from "antd"
const {Text}= Typography
const propertyFor = [
    {
        name: 'Najla',
        id : 1,
        label: 'Najla',
        value: 'Najla',
    },
    {
        name: 'Client',
        id : 2,
        label: 'Client',
        value: 'Client',
    },
]

const propertyCity = [
    {
        name: 'Riyadh',
        id: 1
    },
    {
        name: 'Jeddah',
        id: 2
    },
    {
        name: 'Dammam',
        id: 3
    },
    {
        name: 'Al Khobar',
        id: 4
    },
    {
        name: 'Tabuk',
        id: 5
    },
    {
        name: 'Makkah',
        id: 6
    },
    {
        name: 'Almadina almonura',
        id: 7
    }
]

const propertytype = [
    {
        name: 'Room',
        id: 1
    },
    {
        name: 'Appartment',
        id: 2
    },
]

const propertyOwnership = [
    {
        name:'Own',
        id: 1
    },
    {
        name: 'Rented',
        id: 2
    }
]
const rentPeriod = [
    {
        name:'Monthly',
        id: 1
    },
    {
        name: '6 Months',
        id: 2
    },
    {
        name: 'Yearly',
        id: 3
    },
    {
        name: '2 Years',
        id: 4
    },
    {
        name: '3 Years',
        id: 5
    },
    {
        name: '6 Years',
        id: 6
    }
]
const languageLookup=[
    {
        id:'en', 
        name:<Space className='w-100'>
                <Image src='/assets/images/english.png' width={'25px'} alt='najla' preview={false}/>
                <Text className='fs-12'>English</Text>
            </Space>
    },
    {
        id:'ar', 
        name:<Space className='w-100'>
            <Image src='/assets/images/arabic.png' width={'25px'} alt='najla' preview={false}/>
            <Text className='fs-12'>Arabic</Text>
            </Space>
    },
  ] 

export {propertyFor, propertyCity, propertytype, propertyOwnership, languageLookup, rentPeriod}