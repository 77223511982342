import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography, Divider, message} from "antd"
import { MyInput, MyRentDate, MySelect, MySingleFileUpload } from "../../Forms"
import { MinusOutlined, MoneyCollectOutlined, PhoneOutlined, PlusOutlined } from "@ant-design/icons"
import { useSelector } from "react-redux"
import { apiCalls } from "../../../shared/Apis"
import { onlyDate, stringDateToJSDate } from "../../../shared"
import dayjs from 'dayjs'
import { integerFLoat } from "../../../shared/helpers/HelperFuntions"

const { Title } = Typography

const AddEditBookingDrawer = ({visible, onClose, editBooking, callBack, reportonly}) => {
  
    const {propertyLookup}= useSelector(state => state?.property)
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
    const [totalBookings, setTotalBookings]= useState(0)
    const [blocked, setBlocked]= useState(false)
  
    useEffect(() => {
        if (visible && editBooking && propertyLookup?.length) {
            form.setFieldsValue({
                propertyID: propertyLookup?.find(fin => fin?.id === editBooking?.propertyID)?.name,
                bookings:[
                    {
                        ...editBooking,
                        checkInOutDate:[dayjs(editBooking?.checkINDate), dayjs(editBooking?.checkOutDate)],
                        bill: null
                    }
                ]
            })
            setTotalBookings(1)
        } else {
            form.resetFields()
            setTotalBookings(0)
            setBlocked(false)
        }
    }, [visible, editBooking, propertyLookup]) 

    const closeDrawer= ()=>{
        form.resetFields()
        onClose()
    }
    const prepareExcelData= (sheetData)=>{
        if(sheetData?.length){
            let columns= ["name", "bookingAmount", "checkInOutDate", "numberOFnights"]
            let temp= [...sheetData?.filter((_, index)=> index>0)]
            for(let i=0; i < temp?.length; i++){
                let obj= {}
                for(let j=0; j<temp[i]?.length; j++){
                    if(columns[j] === "checkInOutDate"){
                        let checkINDate= temp[i][j]
                        if(typeof checkINDate === 'number')
                            checkINDate= stringDateToJSDate(checkINDate)
                        obj={...obj, [columns[j]]: [dayjs(checkINDate), dayjs(checkINDate)?.add(temp[i][j+1], "days")]}
                    }
                    else if(columns[j] === "numberOFnights"){
                        obj={...obj, [columns[j]]: temp[i][j], najlaComission: 100, airbnbComission: integerFLoat(temp[i][1]*0.10)}
                    }
                    else
                        obj={...obj, [columns[j]]: temp[i][j]}
                }
                temp[i]= obj
            }
            console.log(temp)
            form.setFieldsValue({
                propertyID: form.getFieldValue("propertyID"),
                bookings: [...temp]
            })
        }
    }
    const [checkBookingLoader, setCheckBookingLoader]= useState(false)

    const checkBooking= async (checkInOutDate)=>{
        let propertyID= form.getFieldValue("propertyID")
        propertyID= propertyLookup?.find(fin => fin?.name === propertyID)?.id
        let checkinDate=null
        let checkOutDate= null
        if(checkInOutDate?.length && propertyID)
        {
            checkinDate= onlyDate(checkInOutDate[0])
            checkOutDate= onlyDate(checkInOutDate[1])
            setCheckBookingLoader(true)
            let result= await apiCalls?.booking?.checkingBooking({propertyID, checkinDate, checkOutDate})
            if(result===2)
                setBlocked(true)
            else 
                setBlocked(false)
            setCheckBookingLoader(false)
        }
    }
    const addUpdateBooking= async ()=>{
        setLoading(true)
        if(blocked){
            message.error('booking found for selected nights')
            setLoading(false)
            return
        }
        let data= form.getFieldsValue()
        let result
        if(data?.bookings?.length>1){
            data={
                propertyID: propertyLookup?.find(fin => fin?.name === data?.propertyID)?.id,
                bookings: data?.bookings?.map(booking=>{
                    const {checkInOutDate}= booking
                    let checkINDate=null
                    let checkOutDate= null
                    if(checkInOutDate?.length)
                    {
                        checkINDate= onlyDate(checkInOutDate[0])
                        checkOutDate= onlyDate(checkInOutDate[1])
                    }
                    return {...booking, checkINDate, checkOutDate}
                })
            }
            result= await apiCalls?.booking?.addMultipleBooking(data)
        }
        else {
            const {propertyID, bill}= data
            const {checkInOutDate}= data?.bookings[0]
            let checkInDate=null
            let checkOutDate= null
            if(checkInOutDate?.length)
            {
                checkInDate= onlyDate(checkInOutDate[0])
                checkOutDate= onlyDate(checkInOutDate[1])
            }
            result= await apiCalls?.booking?.checkingBooking({propertyID: propertyLookup?.find(fin => fin?.name === propertyID)?.id, checkinDate: checkInDate, checkOutDate})
            if(result===2){
                setLoading(false)
                return
            }  
            data= data?.bookings[0]
            const formdata = new FormData()
            if(editBooking?.id)
                formdata.append("id", editBooking?.id)
            formdata.append("propertyID", propertyLookup?.find(fin => fin?.name === propertyID)?.id)
            formdata.append("name", data?.name)
            formdata.append("contactNumber", data?.contactNumber)
            formdata.append("checkINDate", checkInDate)
            formdata.append("checkOutDate", checkOutDate)
            formdata.append("numberOFnights", data?.numberOFnights)
            formdata.append("bookingAmount", data?.bookingAmount)
            formdata.append("najlaComission", data?.najlaComission)
            formdata.append("airbnbComission", data?.airbnbComission)
            if(bill)
                formdata.append("bill", bill)
            result= await apiCalls?.booking?.addUpdateBooking(formdata)
        }
        if(result){
            closeDrawer()
            callBack()
        }
        setLoading(false)
        }

  return (
    <Drawer
        title={editBooking ? 'Edit booking' : 'Add booking'}
        onClose={closeDrawer}
        className=""
        open={visible}
        width={totalBookings > 1 ? 1100 : 800}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                    disabled={!totalBookings || checkBookingLoader}
                >
                    {editBooking? 'Update' : 'Save'}
                </Button>
            </Space>
        } 
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            onFinish={addUpdateBooking}
            requiredMark={false}
        >
            <Row gutter={16}>
                {
                    !reportonly &&
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MySelect
                            label= 'Property'
                            name="propertyID" 
                            placeholder='e.g select'
                            required
                            size='large'
                            message='Please select property'
                            options={propertyLookup}
                            disabled={editBooking || checkBookingLoader? true : false}
                            autoClearSearchValue
                            showSearch
                        />
                    </Col>
                }
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500 danger-color">BOOKING DETAILS:</Title>
                </Col>
                <Col span={24}>
                    <MySingleFileUpload
                        name='csv'
                        label='Upload csv'
                        form={form}
                        prepareExcelData={prepareExcelData}
                        disabled={checkBookingLoader}
                    />
                </Col>
                <Divider>or</Divider>
                <Col span={24}>
                    <Form.List name="bookings">
                        {
                            (fields, { add, remove }) => (
                                <Space direction="vertical" className="width-100">
                                    <Button 
                                        type="primary" 
                                        onClick={() => {
                                            setTotalBookings(fields?.length+1);
                                            add()
                                        }} 
                                        block 
                                        size="large"
                                        icon={<PlusOutlined />}
                                        disabled={fields?.length>=100 || checkBookingLoader}
                                    >
                                        Add booking
                                    </Button>
                                    {
                                        fields?.map(({ key, name}) => (
                                            <> 
                                                {
                                                    key<(fields?.length) &&
                                                    <Divider>
                                                        Booking #{key+1}
                                                    </Divider>
                                                }
                                                <Row key={key} gutter={[16, 0]} align="middle">
                                                    <Col span={totalBookings>1 ? 7 : 12}>
                                                        <MyInput
                                                            label='Guest name'
                                                            name={[name, 'name']}
                                                            placeholder='e.g John Doe'
                                                            required
                                                            size='large'
                                                            message='Please enter guest name'
                                                            disabled={checkBookingLoader}
                                                        />
                                                    </Col>
                                                    <Col span={totalBookings>1 ? 7 : 12}>
                                                        <MyInput
                                                            label='Guest Contact Number'
                                                            name={[name, 'contactNumber']}
                                                            placeholder='e.g +966 331 766 152'
                                                            size='large'
                                                            addonAfter={<PhoneOutlined/>}
                                                            disabled={checkBookingLoader}
                                                        />
                                                    </Col>
                                                    <Col span={totalBookings>1 ? 10 : 24}>
                                                        <MyRentDate
                                                            rangePicker
                                                            label="Check-out / Check-out date"
                                                            name={[name, 'checkInOutDate']}
                                                            required
                                                            size='large'
                                                            message="Please select check-out / check-out date"
                                                            onChange={(checkInOutDate)=>{
                                                                if(checkInOutDate?.length){
                                                                    let bookings= [...form.getFieldValue("bookings")]
                                                                    bookings[key]={...bookings[key], numberOFnights: checkInOutDate[1].diff(checkInOutDate[0], 'day')}
                                                                    form.setFieldValue("bookings", bookings)
                                                                    checkBooking(checkInOutDate)
                                                                }
                                                            }}
                                                            disabled={checkBookingLoader}
                                                        />
                                                    </Col>
                                                    {/* <Col span={totalBookings > 1 ? 4 : 0}>
                                                        <div>
                                                        <Button 
                                                            type="primary" 
                                                            onClick={() => {
                                                                setTotalBookings(fields?.length-1);
                                                                remove()
                                                            }} 
                                                            block 
                                                            // disabled={fields?.length>1}
                                                        >
                                                            <MinusOutlined />
                                                        </Button>
                                                        </div>
                                                    </Col> */}
                                                    <Col span={totalBookings>1 ? 6 : 12}>
                                                        <MyInput
                                                            label='Number of Nights'
                                                            name={[name, 'numberOFnights']}
                                                            placeholder='e.g 3'
                                                            required
                                                            size='large'
                                                            message='Please enter a number'
                                                            disabled={checkBookingLoader}
                                                        />
                                                    </Col>
                                                    <Col span={totalBookings>1 ? 6 : 12}>
                                                        <MyInput
                                                            label='Total booking amount'
                                                            name={[name, 'bookingAmount']}
                                                            placeholder='e.g 3000'
                                                            required
                                                            size='large'
                                                            message='Please enter booking amount'
                                                            addonAfter={<MoneyCollectOutlined />}
                                                            onChange={e=> {
                                                                let bookings= [...form.getFieldValue("bookings")]
                                                                bookings[key]={...bookings[key], airbnbComission: (e.target.value)*0.10}
                                                                form.setFieldValue("bookings", bookings)
                                                            }}
                                                            disabled={checkBookingLoader}
                                                        />
                                                    </Col>
                                                    <Col span={totalBookings>1 ? 6 : 12}>
                                                        <MyInput
                                                            label='Najla Comission'
                                                            name={[name, 'najlaComission']}
                                                            placeholder='e.g 300'
                                                            required
                                                            size='large'
                                                            message='Please enter booking amount'
                                                            addonAfter={<MoneyCollectOutlined />}
                                                            disabled={checkBookingLoader}
                                                        />
                                                    </Col>
                                                    <Col span={totalBookings>1 ? 6 : 12}>
                                                        <MyInput
                                                            label='Airbnb comission(10%)'
                                                            name={[name, 'airbnbComission']}
                                                            placeholder='e.g 800'
                                                            required
                                                            size='large'
                                                            message='Please enter booking amount'
                                                            addonAfter={<MoneyCollectOutlined />}
                                                            disabled={checkBookingLoader}
                                                        />
                                                    </Col>
                                                    {/* <Col span={1} className="justify-end">
                                                        <MinusCircleOutlined onClick={() => remove(name)}/>
                                                    </Col> */}
                                                </Row>
                                            </>
                                        ))
                                    }
                                </Space>
                            )
                        }
                    </Form.List>
                </Col>
                <Col span={totalBookings === 1 ? 24 : 0}>
                    {
                        totalBookings === 1 &&
                        <MySingleFileUpload
                            name= 'bill'
                            label='Bill (pdf)'
                            form={form}
                            application={editBooking?.bill || null}
                            disabled={checkBookingLoader}
                        />
                    }
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditBookingDrawer}