import React, { useState, useEffect} from 'react'
import { Button, Table, Card, Col, Flex, Row, Space, Typography, Form} from 'antd'
import { ActionButton, AlertModal, ModuleTopHeading, MyRadioGroup, MyRentDate, MySelect } from '../../components'
import { AddEditBookingDrawer } from '../../components/BookingComponents/AddEditBookingDrawer'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { actionsApi, onlyDate, TableLoader } from '../../shared'
import { apiCalls } from '../../shared/Apis'
import { bookingColumns } from '../../data/columns'
import { propertyFor } from '../../shared/lookups'
import { useTranslation } from 'react-i18next'
const {Text}= Typography


const Booking = () => {
    const {t}= useTranslation()
    const dispatch= useDispatch()
    const [form] = Form.useForm()
    const {data, loading, pagination}= useSelector(state => state?.booking)
    const {clientLookup}= useSelector(state => state?.client)
    const {propertyByClientLookup}= useSelector(state => state?.property)
    const {language}= useSelector(state => state?.app)
    const [ visible, setVisible ] = useState(false)
    const [ editbook, setEditBook ] = useState(null)
    const [ deleteItem, setDeleteItem ] = useState(false)
    const [propertyAddedFor, setPropertyAddedFor]= useState("Client")
    const user= localStorage.getItem("user")
    const {role}= user ? JSON.parse(user) : {role: null}

    useEffect(()=>{
        dispatch(actionsApi?.getPropertyLookup())
        if(!clientLookup?.length)
            dispatch(actionsApi?.getClientLookup())
    }, [])
 
    useEffect(()=>{
        if(user && role==='host' && clientLookup?.length){
            let {clientID}= JSON.parse(user)
            let clientName= clientLookup?.find(fin => fin?.id === clientID)?.name
            form.setFieldValue("clientID", clientName || null)
            getPropertyByClient(clientID, 2)
            form.setFieldValue("propertyID", null)
        }
    }, [user, role, clientLookup])
    const call=(pageNo=1, pageSize=20)=>{
        dispatch(actionsApi?.getAllBookings({
            payload: getPayload(), 
            pageNo, 
            pageSize
        }))
    }

    function searchBooking() {
        dispatch(actionsApi?.getAllBookings({
            payload: getPayload(),
            pageNo:1, 
            pageSize: pagination?.pageSize
        }))
    }
    const getPayload= ()=>{
        let data= form.getFieldsValue()
        const {fromToDate}= data
        let fromDate=null
        let toDate= null
        if(fromToDate?.length)
        {
            fromDate= onlyDate(fromToDate[0])
            toDate= onlyDate(fromToDate[1])
        }
        return {
            propertyID: propertyByClientLookup?.find(fin => fin?.name === data?.propertyID)?.id,
            fromDate,
            toDate
        }
    }
    
    const getPropertyByClient= (clientID, propertyAddedFor)=>{
        dispatch(actionsApi?.getPropertyByClientLookup({clientID, propertyAddedFor}))
    }

    const columns = [
        ...bookingColumns,
        {
            title: <Text strong>{t("Action")}</Text>,
            key: 'action',
            fixed: 'right',
            align: language === 'ar' ? 'right' : 'left',
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title={t('show booking')}
                        shape="circle"
                        icon={<EyeOutlined/>}
                        onClick={() => { setVisible(true) ; setEditBook(row)}}
                    />
                    {
                        role!=='host' &&
                        <>
                            <ActionButton
                                title={t('edit booking')}
                                shape="circle"
                                icon={<EditOutlined/>}
                                onClick={() => { setVisible(true) ; setEditBook(row)}}
                            />
                            <ActionButton
                                title={t('delete booking')}
                                icon={<DeleteOutlined/>}
                                onClick={() => {  setDeleteItem({id: row?.id, name: row?.name}) }}
                                danger
                            />
                        </>
                    }
                </Space>
            )
        },
    ]
    const [deletionLoading, setDeletionLoading]= useState(false)
    const deleteBooking= async ()=>{
        setDeletionLoading(true)
        let result= await apiCalls?.booking?.deleteBooking(deleteItem?.id)
        if(result){
            setDeleteItem(null)
            call()
        }
        setDeletionLoading(false)
    }

    return (
        <div>
            
            <Card className='shadow-c radius-12 border0'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        {
                            role!=='host' ?
                            <ModuleTopHeading name={t('Booking')} onClick={()=>setVisible(true)} />:    
                            <ModuleTopHeading name={t('Booking')}/>    
                        }            
                    </Col>
                    <Col span={24}>
                        <Space wrap className='mbl-space'>
                            {
                                role!=='host' &&
                                <MyRadioGroup
                                    options={propertyFor}
                                    defaultValue={'Client'}
                                    size='large'
                                    onChange={e => {
                                        if(e?.target?.value==="Najla"){
                                            getPropertyByClient(null, 1)
                                            form.setFieldValue("clientID", null)
                                        }
                                        else
                                            dispatch(actionsApi?.setPropertyByClientLookup([]))
                                        setPropertyAddedFor(e.target.value)
                                        form.setFieldValue("propertyID", null)
                                    }}
                                    className='trans-down'
                                />
                            }
                            <Form
                                form={form}
                                layout="vertical"
                            >
                                <Flex justify='flex-end' align='center' className='mbl-vertical w-100' wrap gap={10}>
                                    {
                                        propertyAddedFor==="Client" &&
                                        <MySelect
                                            name='clientID'
                                            label={t('Client')}
                                            size='large' 
                                            placeholder={t('select...')}
                                            options={clientLookup}
                                            className='width-200'
                                            showSearch
                                            onChange= {(value)=>{
                                                value= clientLookup?.find(fin => fin?.name === value).id
                                                getPropertyByClient(value, 2)
                                                form.setFieldValue("propertyID", null)
                                            }} 
                                            disabled={role==='host'}
                                        />
                                    }
                                    <MySelect
                                        name='propertyID'
                                        label={t('Property')}
                                        size='large' 
                                        placeholder={t('select...')}
                                        options={propertyByClientLookup}
                                        className='width-200'
                                        required
                                        message= 'please select property'
                                        showSearch
                                    />
                                    <MyRentDate
                                        rangePicker
                                        label={t("From to date")}
                                        name={'fromToDate'}
                                        size='large'
                                        className='width-380'
                                        placeholder={t('select from to date')}
                                    />
                                    <Button 
                                        type='primary' 
                                        size='large' 
                                        className='trans-down' 
                                        onClick={searchBooking}
                                    >
                                        {t("Search")}
                                    </Button>
                                </Flex>
                            </Form>          
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Table
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: "max-content"}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: pagination?.totalRecords,
                                pageSize: pagination?.pageSize,
                                defaultPageSize: pagination?.pageSize,
                                current: pagination?.pageNo,
                                size: "default",
                                pageSizeOptions: ['10', '20', '50', '100'],
                                onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='bg-secondary-color border0'>Total: {total}</Button>,
                            }}
                            loading={
                                {
                                    ...TableLoader,
                                    spinning: loading
                                }
                            }
                        />
                    </Col>
                </Row>
            </Card>
            <AddEditBookingDrawer
                visible={visible}
                editBooking={editbook}
                callBack={call}
                onClose={()=> {setVisible(false);setEditBook(null)}}
            />
            <AlertModal 
                visible={deleteItem}
                callBack= {deleteBooking}
                deletionLoading={deletionLoading}
                onClose={()=>setDeleteItem(null)}
                booking
            />
        </div>
    )
}

export { Booking } 
